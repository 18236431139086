import { OrderStep } from '../components/orders/ModalNavigation/ModalNavigation';
import {
  accessoriesOrderState,
  anchorsOrderState,
  ClientSwatch,
  featureOrderState,
  initialOrderState,
  LinerMaterials,
  OrderState,
  productOrderState,
  projectOrderState,
  shippingOrderState,
  specOrderState,
} from './OrderContext';
import { OrderStatus, ServerOrder } from '../models/Order';
import { LabeledResources } from 'sections/orders/Orders';

export type OrderActionType =
  | { type: 'updateStep'; payload: number }
  | { type: 'updateSteps'; payload: OrderStep[] }
  | { type: 'updateAnchors'; payload: Partial<anchorsOrderState> }
  | { type: 'updateFeatures'; payload: Partial<featureOrderState> }
  | { type: 'updateProduct'; payload: Partial<productOrderState> }
  | { type: 'updateShipping'; payload: Partial<shippingOrderState> }
  | { type: 'updateProject'; payload: Partial<projectOrderState> }
  | { type: 'updateAccessories'; payload: Partial<accessoriesOrderState> }
  | { type: 'updateSpec'; payload: Partial<specOrderState> }
  | { type: 'setSwatches'; payload: ClientSwatch[] }
  | { type: 'setLinerMaterials'; payload: LinerMaterials }
  | { type: 'setLinerBeads'; payload: any }
  | { type: 'resetOrder' }
  | { type: 'updateOrder'; payload: Partial<ServerOrder> }
  | { type: 'setFeaturesData'; payload: LabeledResources[] }
  | { type: 'setDecksData'; payload: LabeledResources[] }
  | { type: 'updateState'; payload: Partial<OrderState> };

type featureFunctionalType = Array<{
  anchorTypeOptions: string | undefined | null;
  cableAssembly: boolean | null | undefined;
  featureName: string | null | undefined;
}>;

/**
 * Since array destructuring leads to a bunch of duplicates, this function should try to only update the correct features
 * @param newFeatures - the array we want to update
 * @param oldFeatures - the existing state we want to replace with new values (if they exist)
 */
const getMatchingFeatures = (
  newFeatures: featureFunctionalType,
  oldFeatures: featureFunctionalType
) => {
  const bigger =
    newFeatures.length > oldFeatures.length ? newFeatures : oldFeatures;
  const lesser =
    newFeatures.length > oldFeatures.length ? oldFeatures : newFeatures;

  // If the state array is bigger then the updates, find any matching values based on the feature name (which should be unique), otherwise return the value from the state
  if (JSON.stringify(bigger) !== JSON.stringify(newFeatures)) {
    const matches = bigger.map((feature) => {
      const match = lesser.find(
        (newFeature) => newFeature.featureName === feature.featureName
      );
      if (match) {
        return { ...match, ...feature };
      } else {
        return feature;
      }
    });
    return matches;
  }
  // If the array we want to use is bigger than the current state, just return that
  else {
    return newFeatures;
  }
};
/**
 * @todo better typings
 * @param state
 * @param action
 */
const OrderReducer = (
  state: OrderState,
  action: OrderActionType
): OrderState => {
  switch (
    action.type // CASE STATEMENT OPEN
  ) {
    // Handlers for product form updates
    case 'updateProduct': {
      return {
        ...state,
        product: { ...state.product, ...(action.payload as productOrderState) },
      };
    }
    case 'updateFeatures': {
      const payloadFeatures = Array.isArray(action.payload.features)
        ? action.payload.features
        : [];
      const stateFeatures = Array.isArray(state.features) ? state.features : [];
      let features;

      if (payloadFeatures.length) {
        features = getMatchingFeatures(payloadFeatures, stateFeatures);
      } else {
        features = state.features.features;
      }

      return {
        ...state,
        features: {
          isValid: action.payload.isValid ?? state.features.isValid ?? false,
          isViewed: action.payload.isViewed ?? state.features.isViewed ?? false,
          features: features ?? state.features.features,
        },
      } as OrderState;
    }
    case 'updateAnchors': {
      return {
        ...state,
        anchors: { ...state.anchors, ...(action.payload as anchorsOrderState) },
      };
    }
    case 'updateAccessories': {
      return {
        ...state,
        accessories: {
          ...state.accessories,
          ...(action.payload as accessoriesOrderState),
        },
      };
    }
    case 'updateShipping': {
      return {
        ...state,
        shipping: {
          ...state.shipping,
          ...(action.payload as shippingOrderState),
        },
      };
    }
    case 'updateProject': {
      // If order type changes before a quote is submitted, reset order to initial order state
      const updatedPayload =
        action.payload?.type !== state?.project?.type &&
        (state.order?.status === undefined ||
          state.order?.status === OrderStatus.Saved)
          ? {
              ...state,
              project: {
                ...state.project,
                ...(action.payload as projectOrderState),
              },
              product: { ...state.product, isViewed: false },
              accessories: { ...state.accessories, isViewed: false },
              anchors: state.anchors,
              decksData: initialOrderState.decksData,
              features: { ...state.features, isViewed: false },
              featuresData: state?.featuresData ?? [],
            }
          : {
              ...state,
              project: {
                ...state.project,
                ...(action.payload as projectOrderState),
              },
            };
      return {
        ...updatedPayload,
      };
    }
    case 'updateSpec': {
      return {
        ...state,
        spec: {
          ...state.spec,
          ...(action.payload as specOrderState),
        },
      };
    }
    case 'resetOrder': {
      return {
        ...initialOrderState,
        project: {
          ...state.project,
          type: undefined,
        },
        decksData: state.decksData,
        featuresData: state.featuresData,
      };
    }
    case 'updateOrder': {
      return {
        ...state,
        order: { ...state.order, ...(action.payload as ServerOrder) },
      };
    }
    case 'setFeaturesData': {
      return { ...state, featuresData: action.payload };
    }
    case 'setDecksData': {
      return { ...state, decksData: action.payload };
    }
    case 'updateState': {
      return { ...state, ...action.payload };
    }
    case 'updateStep': {
      return { ...state, step: action.payload };
    }
    case 'updateSteps': {
      return { ...state, steps: action.payload };
    }
    case 'setSwatches': {
      return { ...state, swatches: action.payload };
    }
    case 'setLinerMaterials': {
      return { ...state, linerMaterials: action.payload };
    }
    case 'setLinerBeads': {
      return { ...state, linerBeads: action.payload };
    }
    default: {
      return { ...state };
    }
  } // CASE STATEMENT CLOSE
};

export default OrderReducer;
