import { FeatureType } from 'models';

const hiddenOptionFeatures = [
  FeatureType.AutoCover,
  FeatureType.DivingBoard,
  FeatureType.Handrail,
  FeatureType.Steps,
];

export const isOptionsHidden = (type: FeatureType) => {
  return hiddenOptionFeatures.some((val) => {
    return type === val;
  });
};
