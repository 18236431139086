import { SmartTable, TransitionGroup } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Empty } from 'res/img/empty.svg';
import { Button, Header, Segment, Select } from 'semantic-ui-react';
import { USER_MSG } from 'strings';
import useSmartTableSortOrder, { TableType } from 'context/SmartTableContext';
import { Dealer, Project, ProjectScanModel } from 'models';
import { useHistory } from 'react-router-dom';
import { Order } from 'models/Order';
import Loader from 'components/layout/loader/Loader';
import DateRangePicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import '../Admin.scss';

const today = new Date();
const projectTableType: TableType = 'projects';

const billableOptions: { [label: string]: string } = {
  Any: '',
  Billable: 'Billable',
  'Not Billable': 'Not Billable',
};

const scanOptions: { [label: string]: string } = {
  Any: '',
  'Single Scan': 'Single Scan',
  'Multi Scan': 'Multi Scan',
};

interface ProjectTabProps {
  projects: Project[];
  dealers: Dealer[];
  loaded: boolean;
  filterByCreatedDate: (createdDate: string, el: Project | Order) => boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ProjectTab(props: ProjectTabProps): React.ReactElement {
  const { projects, dealers, loaded, filterByCreatedDate, setLoaded } = props;
  const [exportData, setExportData] = useState(false);
  const history = useHistory();
  const { projectsTableState, updateTableFilteringState } =
    useSmartTableSortOrder();

  const billableFilter =
    projectsTableState.filters?.get('billableFilter') ?? '';
  const projectDealerFilter =
    projectsTableState.filters?.get('dealerFilter') ?? '';
  const scanFilter = projectsTableState.filters?.get('scanFilter') ?? '';
  const projectCreatedDate =
    projectsTableState.filters?.get('createdDate') ?? '';

  useEffect(() => {
    setBillable('');
    setScanFilter('');
    setDealerFilter('');
    setCreatedDate('');
  }, []);

  const setBillable = (value: string) => {
    updateTableFilteringState('billableFilter', value, projectTableType);
  };

  const setScanFilter = (value: string) => {
    updateTableFilteringState('scanFilter', value, projectTableType);
  };

  const companyMappings = useCallback(
    () =>
      dealers
        .map((dealer: Dealer) => ({
          key: dealer.id,
          value: dealer.id,
          text: `${dealer.name} (${dealer.rewardsId})`,
        }))
        .sort((a, b) =>
          a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase())
        ),
    [dealers]
  );

  const setDealerFilter = (value: string) => {
    updateTableFilteringState('dealerFilter', value, projectTableType);
  };

  const setCreatedDate = (value: string) => {
    updateTableFilteringState('createdDate', value, projectTableType);
  };

  const checkBillableAddress = (proj: Project) => {
    // Projects are only considered Billable if there is no other project that has the same address1, city, state, and postalcode values
    // (disregarding capitalization and extra white spaces). If there are different projects with the same address object, then only the first
    // project with that address is considered Billable. No charges will be applied to the other projects with the duplicate address.

    // If the user has not entered a value for any of the address properties, then the project is considered Billable until the address has been populated.
    if (
      proj.address.address1 === '' &&
      proj.address.city === '' &&
      proj.address.state === '' &&
      proj.address.postalcode === ''
    ) {
      return billableFilter === '' ? true : billableFilter === 'Billable';
    }

    // To determine if a project is billable, filter the project list so that all projects with the same address1, city, state, and postalcode values are returned
    // If 0 projects are returned, then the project is Billable. If > 0 projects are returned, then the project is not Billable unless it is the first of it's address.
    const duplicateProjects = projects.filter((p: Project) => {
      return (
        p.id !== proj.id &&
        proj.address.address1?.toLowerCase().replace(' ', '') ===
          p.address.address1?.toLowerCase().replace(' ', '') &&
        proj.address.city?.toLowerCase().replace(' ', '') ===
          p.address.city?.toLowerCase().replace(' ', '') &&
        proj.address.state?.toLowerCase().replace(' ', '') ===
          p.address.state?.toLowerCase().replace(' ', '') &&
        proj.address.postalcode?.toLowerCase().replace(' ', '') ===
          p.address.postalcode?.toLowerCase().replace(' ', '')
      );
    });

    // The oldest address in a list of projects with duplicate addresses is considered Billable. All other projects with that address is Non Billable.
    if (duplicateProjects.length > 0) {
      if (proj.createdAt.localeCompare(duplicateProjects[0].createdAt) === -1) {
        return billableFilter === '' ? true : billableFilter === 'Billable';
      }
    }

    return (
      billableFilter === '' ||
      (billableFilter === 'Billable'
        ? duplicateProjects.length === 0
        : duplicateProjects.length > 0)
    );
  };

  const checkMultiScan = (proj: Project) => {
    if (proj?.scans && proj?.scans?.length > 0) {
      return (
        scanFilter === '' ||
        (scanFilter === 'Single Scan'
          ? proj.scans.length === 1
          : proj.scans.length > 1)
      );
    }
    return scanFilter === '';
  };

  const filterProjectsByDealers = (el: Project) =>
    projectDealerFilter === el.dealerId || projectDealerFilter === '';

  const filter = useCallback(
    (proj: Project) => {
      const isBillable = checkBillableAddress(proj);
      const isMultiScan = checkMultiScan(proj);
      return (
        filterByCreatedDate(projectCreatedDate, proj) &&
        filterProjectsByDealers(proj) &&
        isBillable &&
        isMultiScan
      );
    },
    [scanFilter, billableFilter, projectCreatedDate, projectDealerFilter]
  );

  const onProjectRowClick = (proj: Project) => {
    window.open(`/projects/${proj.id}`, '_blank');
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="admin">
      <TransitionGroup isVisible={loaded}>
        <div className="admin-tab">
          {projects.length > 0 ? (
            <Segment className="admin-table">
              <Header className="table-header" size="large">
                Projects
              </Header>
              <div className="order-icon">
                <div className="project-tooltiptext order-tooltiptext">
                  {`Number of Projects: ${projects.length}`}
                </div>
                <i className="order-info info circle icon" />
              </div>
              <Button
                className="export-button"
                primary
                onClick={() => setExportData(true)}
                content={<i className="download icon" style={{ margin: 0 }} />}
              />
              <div className="controls mbottom">
                <DateRangePicker
                  type="range"
                  maxDate={today}
                  onChange={(_, data) => {
                    const values = data.value as Date[];
                    if (!values) setCreatedDate('');
                    if (values?.length == 2) {
                      const formatter = new Intl.DateTimeFormat();
                      const [startDate, endDate] = values;
                      setCreatedDate(
                        `${formatter.format(startDate)}-${formatter.format(
                          endDate
                        )}`
                      );
                    }
                  }}
                />
                <Select
                  data-cy="project-dropdown-dealer"
                  placeholder="Dealer"
                  options={companyMappings()}
                  onChange={(_, { value }) =>
                    setDealerFilter(value?.toString() ?? '')
                  }
                  value={projectDealerFilter}
                  forceSelection={false}
                  selectOnBlur={false}
                  clearable
                  search
                />
                <Select
                  data-cy="project-dropdown-billable"
                  placeholder="Billing Status"
                  options={Object.entries(billableOptions).map(
                    ([text, value]) => ({
                      text,
                      value,
                    })
                  )}
                  onChange={(_, { value }) => setBillable(String(value))}
                  value={billableFilter}
                  clearable
                  search
                />
                <Select
                  data-cy="project-dropdown-scan"
                  placeholder="Scan Type"
                  options={Object.entries(scanOptions).map(([text, value]) => ({
                    text,
                    value,
                  }))}
                  onChange={(_, { value }) => setScanFilter(String(value))}
                  value={scanFilter}
                  clearable
                  search
                />
              </div>
              <SmartTable
                className="projects-table data-scroll"
                tableType="projects"
                data={projects}
                model={ProjectScanModel}
                excludeProps=" notes address.address2 customer.name customer.email customer.phone status "
                initialSortProp="createdAt"
                transformProps={{
                  createdAt: (val) => val.split('T')[0],
                }}
                filter={filter}
                onRowClick={onProjectRowClick}
                empty={<Empty />}
                exportData={exportData}
                setExportData={setExportData}
              />
            </Segment>
          ) : (
            <>
              {loaded && projects.length === 0 ? (
                <Loader text={USER_MSG.PROJECTS_LOADING} />
              ) : (
                <Loader text={USER_MSG.PROJECT_DATA_NOT_FOUND} />
              )}
            </>
          )}
        </div>
      </TransitionGroup>
    </div>
  );
}
