import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Header, Segment, Card, Form } from 'semantic-ui-react';
import { FieldGroup, Modal, TransitionGroup } from 'components';
import {
  PurchasePartner,
  PurchasePartnerModel,
  PurchasePartnerWithBrands,
  TYPE_BUYING_GROUP,
  TYPE_DIRECT,
  TYPE_DISTRIBUTOR,
} from 'models/Dealer';
import { useAuth, useFeatureFlags, useUI } from 'services';
import { USER_MSG } from 'strings';
import './CompanyDetails.scss';
import { Role } from 'models/Role';

const PURCHASE_PARTNERS = 'Purchase Partners';
const CODE = 'Code';

type DealerDistributorProps = {
  purchasePartners?: PurchasePartnerWithBrands[];
  setDefaultPurchasePartner: (dist: PurchasePartner) => void;
  removePurchasePartner: (dist: PurchasePartner) => void;
  error?: boolean;
};

export default function DealerDistributor({
  purchasePartners,
  setDefaultPurchasePartner,
  removePurchasePartner,
  error,
}: DealerDistributorProps): React.ReactElement {
  const { showError } = useUI();
  const flags = useFeatureFlags();
  const [purchasePartnerCode, setPurchasePartnerCode] = useState<string>('');
  const [modalState, setModalState] = useState<boolean>(false);
  const [currentPurchasePartner, setCurrentPurchasePartner] = useState(
    PurchasePartnerModel({} as PurchasePartner)
  );
  const [loaded, setLoaded] = useState<boolean>(false);
  const { user } = useAuth();
  const hasEditAccess =
    user?.role !== Role.DESIGN &&
    user?.role !== Role.SALES &&
    user?.role !== Role.CUST_SERV;

  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);

  const validatePurchasePartner = useCallback(() => {
    const found = purchasePartners?.find(
      (purchasePartner: PurchasePartner) =>
        purchasePartner.type === TYPE_BUYING_GROUP
    );
    if (!found || currentPurchasePartner.data.type === TYPE_DISTRIBUTOR)
      return false;
    else if (
      currentPurchasePartner.data.type === TYPE_BUYING_GROUP &&
      found?.code === currentPurchasePartner.data.code
    )
      return false;
    else if (currentPurchasePartner.data.type === TYPE_DIRECT) return false;
    return true;
  }, [currentPurchasePartner, purchasePartners]);

  const handleOpenModal = (purchasePartner: PurchasePartner): void => {
    setModalState(true);
    setPurchasePartnerCode(purchasePartner.code);
    setCurrentPurchasePartner(PurchasePartnerModel(purchasePartner));
  };

  const handleAddPurchasePartner: any = useCallback(() => {
    if (!currentPurchasePartner.isValid()) {
      return showError(USER_MSG.ERROR_INVALID);
    } else if (validatePurchasePartner()) {
      return showError(USER_MSG.ERROR_INVALID_BUYING_GROUP);
    }
    setModalState(false);
    if (purchasePartnerCode) {
      setDefaultPurchasePartner(currentPurchasePartner.data);
    }
  }, [currentPurchasePartner, validatePurchasePartner, showError]);

  const handleRemovePurchasePartner: any = useCallback(() => {
    setModalState(false);
    removePurchasePartner(currentPurchasePartner.data);
  }, [setModalState, removePurchasePartner, currentPurchasePartner]);

  const renderModelControls: React.ReactElement = useMemo(() => {
    return (
      <div className="controls">
        <Button
          className="control-right"
          basic
          onClick={() => setModalState(false)}
          content="Cancel"
        ></Button>
        <Button
          primary
          onClick={() => handleAddPurchasePartner()}
          content={'Set As Default'}
        ></Button>
      </div>
    );
  }, [
    purchasePartnerCode,
    handleRemovePurchasePartner,
    handleAddPurchasePartner,
  ]);

  return (
    <TransitionGroup isVisible={loaded}>
      <Segment className="pad">
        <Header size="large">{PURCHASE_PARTNERS}</Header>
        {error ? (
          <Header size="small">
            There was an error getting your purchase partners. Please try again
            later.
          </Header>
        ) : (
          <div className="card-group">
            {purchasePartners?.map(
              (purchasePartner: PurchasePartnerWithBrands, index: number) => (
                <Card
                  key={index}
                  onClick={() =>
                    hasEditAccess ? handleOpenModal(purchasePartner) : null
                  }
                >
                  <Card.Header>{purchasePartner.name}</Card.Header>
                  <Card.Meta>{purchasePartner.type || '' + CODE}</Card.Meta>
                  <Card.Meta>
                    <span className="brand-title">Brands:</span>
                    <div className="brand-container">
                      {purchasePartner?.brands?.map((brand, i) => (
                        <div key={i} className="brand-name">
                          {`${brand?.name} (${brand?.product})`}
                        </div>
                      ))}
                    </div>
                  </Card.Meta>
                </Card>
              )
            )}
          </div>
        )}
      </Segment>
      <Modal
        isOpen={modalState}
        title="Purchase Partner Information"
        size="tiny"
        actions={renderModelControls}
      >
        <Form>
          <FieldGroup
            model={currentPurchasePartner}
            fields="name"
            disabled={true}
            readonly={true}
          />
          {flags.allowFullPurchasePartnerPrivileges ? (
            <div className="mtop">
              <FieldGroup
                model={currentPurchasePartner}
                fields="code"
                disabled={true}
                readonly={true}
              />
            </div>
          ) : null}
          <div className="mtop">
            <FieldGroup
              model={currentPurchasePartner}
              fields="isDefault"
              disabled={true}
              readonly={true}
            />
          </div>
          <div className="mtop">
            <FieldGroup
              model={currentPurchasePartner}
              fields="type"
              disabled={true}
              readonly={true}
            />
          </div>
        </Form>
      </Modal>
    </TransitionGroup>
  );
}
