import React from 'react';
import { Menu } from 'semantic-ui-react';
import './ModalNavigation.scss';
import { OrderContext } from '../../../context/OrderContext';
import { OrderStatus, OrderType } from 'models';
import { useAuth } from 'services';
import { Role } from 'models/Role';

export type OrderStep =
  | 'product'
  | 'project'
  | 'features'
  | 'anchors'
  | 'accessories'
  | 'shipping'
  | 'review'
  | 'spec';

const labels: Record<OrderStep, string> = {
  product: 'Product',
  project: 'Order Details',
  features: 'Features',
  anchors: 'Anchors',
  accessories: 'Accessories',
  shipping: 'Ship To',
  review: 'Review Order',
  spec: 'Order Data',
};

const ReviewQuote = 'Review Quote';

interface ModalNavigationProps {
  currentStep: number;
  onclick: (index: number) => void;
  disabled: boolean;
}

const ModalNavigation = (props: ModalNavigationProps) => {
  const { state } = React.useContext(OrderContext);
  const { user } = useAuth();

  const steps: Array<{ title: string; isValid: boolean }> = state.steps.map(
    (step) => {
      const isAfterQuote =
        state.order?.status === OrderStatus.QuotedOptions ||
        state.order?.status === OrderStatus.ReadyForOrder ||
        state.order?.status === OrderStatus.Ordered;
      const isLiner =
        state.order?.type === OrderType.Liner ||
        state.project?.type === OrderType.Liner;
      // TODO: only show shared steps if type isn't selected
      const quoteSteps = isLiner
        ? state.product.isValid &&
          state.project.isValid &&
          state.features.isValid &&
          state.accessories.isValid
        : state.product.isValid &&
          state.project.isValid &&
          state.features.isValid &&
          state.anchors.isValid &&
          state.accessories.isValid;
      const orderSteps = isLiner
        ? state.product.isValid &&
          state.project.isValid &&
          state.features.isValid &&
          state.accessories.isValid &&
          state.shipping.isValid &&
          state.spec.isValid
        : state.product.isValid &&
          state.project.isValid &&
          state.features.isValid &&
          state.anchors.isValid &&
          state.accessories.isValid &&
          state.shipping.isValid &&
          state.spec.isValid;
      return {
        title: step === 'review' && !isAfterQuote ? ReviewQuote : labels[step],
        isValid:
          step === 'review'
            ? isAfterQuote &&
              user?.role !== Role.ADMIN &&
              user?.role !== Role.USER
              ? orderSteps
              : quoteSteps
            : state[step].isValid,
      };
    }
  );

  return (
    <Menu borderless vertical className="modal-navigation">
      {steps.map((step, index) => {
        const isCurrentStep = index === +props.currentStep;
        return (
          <Menu.Item
            key={index}
            name={step.title}
            disabled={step.title !== 'Review' && props.disabled}
            icon={step.isValid ? 'check circle' : 'circle outline'}
            className={`${
              isCurrentStep ? 'active' : ''
            } modal-navigation__item`}
            onClick={() => props.onclick(index)}
          />
        );
      })}
    </Menu>
  );
};

export default ModalNavigation;
