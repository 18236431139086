import { Modal, SmartTable, TransitionGroup } from 'components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as Empty } from 'res/img/empty.svg';
import { Button, Header, Segment, Select } from 'semantic-ui-react';
import { USER_MSG } from 'strings';
import useSmartTableSortOrder, { TableType } from 'context/SmartTableContext';
import {
  Dealer,
  Project,
  ProjectModel,
  OneToManyModel,
  DealerProjects,
} from 'models';
import { useHistory } from 'react-router-dom';
import Loader from 'components/layout/loader/Loader';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import '../Admin.scss';

const projectsWithDealerTableType: TableType = 'projectsWithDealer';

const userDealerOptions: { [label: string]: string } = {
  Any: '',
  'One To Many Profile': 'One To Many Profile',
  Builder: 'Builder',
};

const scanOptions: { [label: string]: string } = {
  Any: '',
  'Single Scan': 'Single Scan',
  'Multi Scan': 'Multi Scan',
};

interface ProjectTabProps {
  projects: Project[];
  projectsWithDealer: DealerProjects[];
  dealers: Dealer[];
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OneToManyTab(
  props: ProjectTabProps
): React.ReactElement {
  const { projects, projectsWithDealer, dealers, loaded, setLoaded } = props;
  const [filteredProjects, setFilteredProjects] = useState([] as Project[]);
  const [open, setOpen] = useState(false);
  const [exportData, setExportData] = useState(false);
  const history = useHistory();
  const { projectsWithDealerTableState, updateTableFilteringState } =
    useSmartTableSortOrder();

  const projectDealerFilter =
    projectsWithDealerTableState.filters?.get('dealerFilter') ?? '';
  const scanFilter =
    projectsWithDealerTableState.filters?.get('scanFilter') ?? '';
  const userDealerFilter =
    projectsWithDealerTableState.filters?.get('userDealerFilter') ?? '';
  const projectCreatedDate =
    projectsWithDealerTableState.filters?.get('createdDate') ?? '';

  useEffect(() => {
    setUserDealerFilter('');
    setScanFilter('');
    setDealerFilter('');
  }, []);

  const setScanFilter = (value: string) => {
    updateTableFilteringState('scanFilter', value, projectsWithDealerTableType);
  };

  const setUserDealerFilter = (value: string) => {
    console.log(value);
    updateTableFilteringState(
      'userDealerFilter',
      value,
      projectsWithDealerTableType
    );
  };

  const companyMappings = useCallback(
    () =>
      dealers
        .map((dealer: Dealer) => ({
          key: dealer.id,
          value: dealer.id,
          text: `${dealer.name} (${dealer.rewardsId})`,
        }))
        .sort((a, b) =>
          a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase())
        ),
    [dealers]
  );

  const setDealerFilter = (value: string) => {
    updateTableFilteringState(
      'dealerFilter',
      value,
      projectsWithDealerTableType
    );
  };

  const checkMultiScan = (proj: DealerProjects) => {
    return (
      scanFilter === '' ||
      (scanFilter === 'Single Scan'
        ? proj?.singleScanCount > 0
        : proj?.multiScanCount > 0)
    );
  };

  const checkUserDealer = (proj: DealerProjects) => {
    return (
      userDealerFilter === '' ||
      (userDealerFilter === 'Builder'
        ? !proj?.isUserDealer
        : proj?.isUserDealer)
    );
  };

  const filterProjectsByDealers = (el: DealerProjects) =>
    projectDealerFilter === el.dealerId || projectDealerFilter === '';

  const filter = useCallback(
    (proj: DealerProjects) => {
      const isMultiScan = checkMultiScan(proj);
      const isUserDealer = checkUserDealer(proj);
      return filterProjectsByDealers(proj) && isMultiScan && isUserDealer;
    },
    [scanFilter, userDealerFilter, projectCreatedDate, projectDealerFilter]
  );

  const onDealerRowClick = (proj: DealerProjects) => {
    setFilteredProjects(
      projects.filter((val: any) => {
        return proj?.projectIds.includes(val.id);
      })
    );
    setOpen(true);
  };

  const onProjectRowClick = (proj: Project) => {
    history.push(`/projects/${proj.id}`);
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  const renderModelControls: React.ReactElement = useMemo(() => {
    return (
      <div className="controls">
        <Button basic onClick={() => setOpen(false)} content="Close" />
      </div>
    );
  }, [setOpen]);

  return (
    <div className="admin">
      <TransitionGroup isVisible={loaded}>
        <Modal
          isOpen={open}
          actions={renderModelControls}
          size="large"
          title="Projects By Dealer"
          className="project-modal"
        >
          <Segment className="admin-table">
            <Header size="large">Projects</Header>
            <SmartTable
              className="projects-table data-scroll"
              tableType="projects"
              data={filteredProjects}
              model={ProjectModel}
              excludeProps=" notes address.address2 customer.name customer.email customer.phone status "
              initialSortProp="createdAt"
              transformProps={{
                createdAt: (val) => val.split('T')[0],
              }}
              onRowClick={onProjectRowClick}
              empty={<Empty />}
            />
          </Segment>
        </Modal>
        <div className="admin-tab">
          {projects.length > 0 ? (
            <Segment className="admin-table">
              <Header className="table-header" size="large">
                Projects Scanned By Dealer
              </Header>
              <Button
                className="export-button"
                primary
                onClick={() => setExportData(true)}
                content={<i className="download icon" style={{ margin: 0 }} />}
              />
              <div className="controls mbottom">
                <Select
                  data-cy="project-dropdown-userDealer"
                  placeholder="Dealer Type"
                  options={Object.entries(userDealerOptions).map(
                    ([text, value]) => ({
                      text,
                      value,
                    })
                  )}
                  onChange={(_, { value }) =>
                    setUserDealerFilter(String(value))
                  }
                  value={userDealerFilter}
                  clearable
                  search
                />
                <Select
                  data-cy="project-dropdown-dealer"
                  placeholder="Dealer"
                  options={companyMappings()}
                  onChange={(_, { value }) =>
                    setDealerFilter(value?.toString() ?? '')
                  }
                  value={projectDealerFilter}
                  forceSelection={false}
                  selectOnBlur={false}
                  clearable
                  search
                />
                <Select
                  data-cy="project-dropdown-scan"
                  placeholder="Scan Type"
                  options={Object.entries(scanOptions).map(([text, value]) => ({
                    text,
                    value,
                  }))}
                  onChange={(_, { value }) => setScanFilter(String(value))}
                  value={scanFilter}
                  clearable
                  search
                />
              </div>
              <SmartTable
                className="projects-table project-scroll"
                tableType="projectsWithDealer"
                data={projectsWithDealer}
                model={OneToManyModel}
                excludeProps=" id userId dealerId projectIds "
                initialSortProp="dealerName"
                transformProps={{
                  createdAt: (val) => val.split('T')[0],
                  scanIdList: (val) =>
                    val?.length === 1
                      ? 'Single Scan'
                      : val?.length > 1
                      ? 'Multi Scan'
                      : 'No Scan',
                  isUserDealer: (val) =>
                    val ? 'One to many Profile' : 'Builder',
                }}
                filter={filter}
                onRowClick={onDealerRowClick}
                empty={<Empty />}
                exportData={exportData}
                setExportData={setExportData}
              />
            </Segment>
          ) : (
            <>
              {loaded && projects.length === 0 ? (
                <Loader text={USER_MSG.PROJECTS_LOADING} />
              ) : (
                <Loader text={USER_MSG.PROJECT_DATA_NOT_FOUND} />
              )}
            </>
          )}
        </div>
      </TransitionGroup>
    </div>
  );
}
