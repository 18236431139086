import React, { useCallback, useState, useMemo } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import { FieldGroup, Modal } from 'components';
import { User, UserModel } from 'models';
import { UserService } from '.';
import { useAuth, useUI } from 'services';
import { USER_MSG } from 'strings';
import UserModalActions from 'components/user/UserModalActions';

interface UserProps {
  onCancel: () => void;
  isOpen: boolean;
}

export default function UserProfile({
  onCancel,
  isOpen,
}: UserProps): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const { user, setUser, logout } = useAuth();
  const [userModel] = useState(UserModel(user || ({} as User)));
  const { showError, showSuccess } = useUI();

  const onSubmit = useCallback(() => {
    if (!userModel.isValid()) {
      setLoading(false);
      showError(USER_MSG.ERROR_INVALID);
      return;
    }
    setLoading(true);
    UserService.set(userModel.data).then(
      () => {
        const email = user?.email;
        setUser(userModel.data);
        showSuccess();
        setLoading(false);
        onCancel();
        if (email !== userModel.data.email) {
          logout();
        }
      },
      () => {
        setLoading(false);
        showError();
      }
    );
  }, [userModel, setUser, showError, showSuccess, onCancel]);

  return (
    <Modal
      title="User Profile"
      actions={<UserModalActions onCancel={onCancel} onSubmit={onSubmit} />}
      isOpen={isOpen}
    >
      <Segment className="pad">
        <Form loading={loading}>
          <FieldGroup model={userModel} />
        </Form>
      </Segment>
    </Modal>
  );
}
