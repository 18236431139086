export type UserMsg = {
  title: string;
  msg: string;
  icon?: string;
};

export const USER_MSG = {
  SUCCESS_TOS: {
    title: 'Done!',
    msg: 'Agreed to Terms of Use.',
  },
  ERROR_INVALID_BUYING_GROUP: {
    title: 'A Buying Group Exists',
    msg: 'A buying group has already been defined. You are limited to 1 buying group. If you need a new buying group please remove the previously defined buying group and try again.',
    icon: '🚫',
  },
  PROJECTS_NOT_FOUND: {
    title: 'No Projects Were Found',
    msg: 'Create new project to get started',
  },
  EMPLOYEES_NOT_FOUND: {
    title: 'No Employees Were Found',
    msg: 'Add a new employee to get started',
  },
  EMPLOYEES_LOADING: {
    title: 'Fetching Employees',
    msg: 'Hang tight while we get your employees!',
  },
  DEALER_NOT_FOUND: {
    title: 'No Dealer Was Found',
    msg: 'Please Contact Latham Admin',
  },
  DEALER_LOADING: {
    title: 'Fetching Dealer',
    msg: 'Hang tight while we get your dealer!',
  },
  SUCCESS_GENERIC: {
    title: 'Done!',
    msg: 'Operation Successful',
  },
  SUCCESS_IMAGE: {
    title: 'Done!',
    msg: 'Resources Uploaded',
  },
  SUCCESS_ORDER_CREATE: {
    title: 'Done!',
    msg: 'Successfully created order',
  },
  SUCCESS_ORDER_DELETE: {
    title: 'Done!',
    msg: 'Successfully deleted order',
  },
  SUCCESS_ORDER_SAVE: {
    title: 'Done!',
    msg: 'Successfully saved order',
  },
  SUCCESS_ORDER_ARCHIVE: {
    title: 'Done!',
    msg: 'Successfully archived order',
  },
  SUCCESS_ORDER_UNARCHIVE: {
    title: 'Done!',
    msg: 'Successfully unarchived order',
  },
  SUCCESS_QUOTE_CREATE: {
    title: 'Done!',
    msg: 'Successfully created quote',
  },
  SUCCESS_QUOTE_SAVE: {
    title: 'Done!',
    msg: 'Successfully saved quote',
  },
  SUCCESS_QUOTE_SUBMIT: {
    title: 'Done!',
    msg: 'Successfully submitted quote to Latham',
  },
  SUCCESS_QUOTE_CANCEL: {
    title: 'Done!',
    msg: 'Successfully canceled quote',
  },
  SUCCESS_QUOTE_ARCHIVE: {
    title: 'Done!',
    msg: 'Successfully archived quote',
  },
  SUCCESS_QUOTE_UNARCHIVE: {
    title: 'Done!',
    msg: 'Successfully unarchived quote',
  },
  SUCCESS_ORDER_SUBMIT: {
    title: 'Done!',
    msg: 'Successfully submitted order to Latham',
  },
  ERROR_SAVING_ORDER: {
    title: 'Your order could not be saved!',
    msg: 'Something went wrong when saving your order',
  },
  ERROR_CREATING_ORDER: {
    title: 'Your order could not be created!',
    msg: 'Something went wrong when creating your order',
  },
  ERROR_NEW_QUOTE: {
    title: 'You cannot create a new quote!',
    msg: 'Please select a pool shape before creating a quote',
  },
  ERROR_DELETING_ORDER: {
    title: 'Your order could not be deleted!',
    msg: 'Something went wrong when deleting your order',
  },
  ERROR_DELETING_QUOTE: {
    title: 'Your quote could not be deleted!',
    msg: 'Something went wrong when deleting your order',
  },
  ERROR_SAVING_PROJECT: {
    title: 'Your project could not be saved!',
    msg: 'Something went wrong when saving your project',
  },
  ERROR_CREATING_PROJECT: {
    title: 'Your project could not be created!',
    msg: 'Something went wrong when creating your project',
  },
  ERROR_DELETING_PROJECT: {
    title: 'Your project could not be deleted!',
    msg: 'Something went wrong when deleting your project',
  },
  ERROR_MEASUREMENT_DATA: {
    title: 'Missing Data',
    msg: 'This project is missing measurement data that is required for a quote. Please upload the project from the iPad that has the measurement.',
  },
  ERROR_GENERIC: {
    title: 'Oops',
    msg: 'Something went wrong, please try again or refresh',
    icon: '💥',
  },
  ERROR_INVALID: {
    title: 'Missing Data',
    msg: 'Please complete all required fields',
    icon: '🚫',
  },
  ERROR_EMAIL_CONFLICTS: {
    title: 'Email Conflicts',
    msg: 'The email address already exists.',
    icon: '🚫',
  },
  ERROR_INVALID_DATA: {
    title: 'Invalid data',
    msg: 'Please enter valid data!',
    icon: '🚫',
  },
  ERROR_INCOMPLETE_PROJECT: {
    title: 'Missing Project Data',
    msg: 'Each of the required project fields must be populated. Return to the project details, populate the required fields, then try again.',
    icon: '🚫',
  },
  ERROR_UNAUTH_ROUTE: {
    title: 'Unauthorized',
    msg: `Your account is not authorized to access this section. Please contact Latham or use another account.`,
    icon: '🚫',
  },
  ERROR_UNAUTH_LOGIN: {
    title: 'Unauthorized',
    msg: `Your account is not authorized to access this site. Please contact Latham or use another account. You will now be redirected to the login page.`,
    icon: '🚫',
  },
  ERROR_PASSWORD_CHANGE_REQUIRED: {
    title: 'Password Change Required',
    msg: `Before logging in you must change your password. An email will be sent to you shortly. You will now be redirected to the login page.`,
    icon: '🚫',
  },
  ERROR_PASSWORD_RESET_REQUIRED: {
    title: 'Password Change Required',
    msg: `Your password must be reset by an administrator. You will now be redirected to the login page.`,
    icon: '🚫',
  },
  ERROR_UNAUTHORIZED_ACCESS: {
    title: 'Unauthorized Access',
    msg: `Your account is not authorized to perform this action. Please contact your administrator for further assistance.`,
    icon: '🚫',
  },
  ERROR_USER_NOT_FOUND: {
    title: 'Sorry',
    msg: `Your account is misconfigured. Please contact Latham or use another account. You will now be redirected to the login.`,
    icon: '🔧',
  },
  ERROR_SERVER: {
    title: 'Sorry',
    msg: `Our systems are experiencing a problem. Please try logging in again.`,
    icon: '🔧',
  },
  ERROR_IMAGE: {
    title: 'Image Upload Failed',
    msg: "Sorry, we couldn't upload the image. Please remove it and try again.",
  },
  ERROR_DEALER_NOT_FOUND: { title: 'Dealer not found' },
  ERROR_DXF: { title: 'Error Generating DXF' },
  CONFIRM_DELETE: {
    title: 'About to Delete {name}',
    msg: 'Are you sure?',
  },
  CONFIRM_DELETE_NAME: {
    title: 'About to Delete {name}',
    msg: 'Are you sure you want to remove {name}?',
  },
  DEV_MODE: {
    title: 'DEV MODE ON',
    msg: 'Now you know Kung-Fu!',
    icon: '😎',
  },
  PROJECTS_LOADING: {
    title: 'Fetching projects',
    msg: 'Hang tight while we get your projects!',
  },
  DEALERS_LOADING: {
    title: 'Fetching dealers',
    msg: 'Hang tight while we get your dealers!',
  },
  ORDERS_LOADING: {
    title: 'Fetching orders',
    msg: 'Hang tight while we get your orders!',
  },
  PROJECT_DATA_NOT_FOUND: {
    title: 'No Projects Were Found',
    msg: 'Try a different filter.',
  },
  DEALER_DATA_NOT_FOUND: {
    title: 'No Dealers Were Found',
    msg: 'Import a new Dealer to get started.',
  },
  ORDER_DATA_NOT_FOUND: {
    title: 'No Orders Were Found',
    msg: 'Create a new Order to get started.',
  },
};

export const ORDER_MSG = {
  ORDER_ARCHIVE: 'Archive Order',
  ORDER_UNARCHIVE: 'Unarchive Order',
  QUOTE_ARCHIVE: 'Archive Quote',
  QUOTE_UNARCHIVE: 'Unarchive Quote',
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
  DELETE_ORDER: 'Delete Order',
  CANCEL_QUOTE: 'Cancel Quote',
  DELETE: 'Delete',
};
