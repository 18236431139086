import React, { useCallback, useEffect, useRef, useState } from 'react';
import ImageUploading, {
  ErrorsType,
  ImageListType,
  ImageType,
  ImageUploadingPropsType,
} from 'react-images-uploading';
import ImageGallery from 'react-image-gallery';
import { Message } from 'semantic-ui-react';
import { ProjectService, ResourceService } from 'sections/projects';
import { useAuth, useUI } from 'services';
import { Project, Resource } from 'models';
import { checkFeatureAuthorization, Features, logger } from '../utils';
import { UploadImageButton } from './project/UploadImageButton';
import { uploadProjectImage } from './project/UploadProjectImage';
import { ProjectImageList } from './project/ProjectImageList';
import { NEW_PROJECT_ID_STRING } from '../sections/projects/ProjectDetail';
import { USER_MSG } from 'strings';

const DATA_KEY = 'url';
const MAX_QTY = 1;
const ERRORS = {
  QTY: `You can upload at most ${MAX_QTY} files`,
  TYPE: 'Unsupported file type',
  SIZE: 'Image size must be 2 MB max, video size 20 MB max',
  RES: 'Image resolution is wrong (?)',
};

const THUMBNAIL_WIDTH = 120;

export interface ImageUploadProps {
  project: Project;
  projId: string;
  setProjId: React.Dispatch<React.SetStateAction<string>>;
  className: string;
  inEditMode?: boolean;
  onError: () => void;
  actualProjectId: string;
}

interface ThumnailImage extends ImageType {
  id: string;
  url: string;
  thumbnailUrl: string;
  source?: string;
  pending?: boolean;
  deleteable?: boolean;
}

const getImageIdFromUrl = (url: string) => {
  const indexOfLastSlash = url.lastIndexOf('/');
  const indexOfLastDash = url.lastIndexOf('-');
  return url.slice(indexOfLastSlash + 1, indexOfLastDash);
};

export default function ImageUpload({
  project = {} as Project,
  className = '',
  projId,
  setProjId,
  inEditMode = false,
  onError,
  actualProjectId,
}: ImageUploadProps): React.ReactElement {
  const { user } = useAuth();
  const gallery = useRef<ImageGallery | null>(null);
  const [images, setImages] = useState<ThumnailImage[]>([]);
  const [fullSizeImages, setFullSizeImages] = useState<ImageListType>([]);

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [galleryVisible, setIsGalleryVisible] = useState(false);
  const { showError, showSuccess } = useUI();

  const uploadImage = uploadProjectImage(
    project,
    projId,
    showSuccess,
    showError
  );

  const removeImage = (
    image: ImageType,
    index: number,
    onImageRemove: { (index: number): void; (arg0: any): any }
  ) => {
    const newImages = images.filter(
      (img, galleryIndex) => galleryIndex !== index
    );

    if (image.file) {
      setImages(newImages);
      onImageRemove(index);
    } else {
      const imageID = getImageIdFromUrl(image.url);
      logger.trace('Image ID to delete %s', imageID);

      ResourceService.del(imageID)
        .then(
          () => {
            onImageRemove(index);
            setImages(newImages);
          },
          () => showError()
        )
        .catch((err) => {
          logger.error(err);
          throw err;
        });
    }
  };

  // Upload images if editing existing project, otherwise flag with pending to upload on create
  const onChange: ImageUploadingPropsType['onChange'] = useCallback(
    (newImages, indexes) => {
      if (indexes?.length) {
        const image = newImages[indexes[0]];
        if (project.id || projId) {
          setUploading(true);
          uploadImage(image)
            .then(() => {
              setUploading(false);
              setImages([image as ThumnailImage, ...images]);
            })
            .catch((error) => {
              throw error;
            });
        } else {
          image.pending = true;
          setImages([image as ThumnailImage, ...images]);
        }
      }
    },
    [project.id, uploadImage, images]
  );

  const onImageClick = (index: number) => {
    if (inEditMode) {
      return;
    } else {
      gallery.current?.slideToIndex(index);
      gallery.current?.fullScreen();
    }
  };

  const getErrorMsgs = (errors: ErrorsType) => {
    if (!errors) return;
    const msg = [];
    if (errors.maxNumber) msg.push(ERRORS.QTY);
    if (errors.acceptType) msg.push(ERRORS.TYPE);
    if (errors.maxFileSize) msg.push(ERRORS.SIZE);
    if (errors.resolution) msg.push(ERRORS.RES);
    return msg;
  };

  // Upload images added during project creation
  useEffect(() => {
    if (!projId || uploading) return;
    const pending = images.filter((img) => img.pending).map(uploadImage);
    if (pending.length) {
      setUploading(true);
      Promise.all(pending)
        .then(() => {
          showSuccess(USER_MSG.SUCCESS_IMAGE);
        }, onError)
        .finally(() => {
          setProjId('');
          setUploading(false);
        });
    }
  }, [projId, images, uploading, uploadImage, onError]);

  /*
    IMAGE FETCHING ON MOUNT
  */
  useEffect(() => {
    // If the user is creating a project or the project has no resources, we don't need to fetch images
    if (
      !actualProjectId ||
      actualProjectId === NEW_PROJECT_ID_STRING ||
      !project.resources ||
      !project.resources.length ||
      inEditMode
    ) {
      return;
    }
    try {
      // Fetch thumbnail images & display loading spinner until they are retrieved
      if (checkFeatureAuthorization(Features.GetImages, user?.role)) {
        setLoading(true);
        ProjectService.getImages(actualProjectId, THUMBNAIL_WIDTH).then(
          (thumbnailUrls) => {
            const thumbnailImages = thumbnailUrls.map((url) => {
              const imageId = getImageIdFromUrl(url);
              // If we have the capture source in project.resources,
              // populate from there to save on network calls
              const source = project.resources?.find(
                (resource) => resource.id === imageId
              )?.captureSource;
              const deleteable =
                (source === 'mobile' || source === 'portal') &&
                imageId !== project.primaryImageId;

              return {
                id: imageId,
                url,
                thumbnailUrl: url,
                source,
                deleteable,
              };
            });

            // We have enough to display the thumbnail images,
            // so set those to state & hide the spinner.
            setImages(thumbnailImages);
            setLoading(false);

            // Fetch full-size image urls
            ProjectService.getImages(actualProjectId).then((result) => {
              const newImages = result.map((imageUrl, index) => {
                return {
                  url: imageUrl,
                  thumbnailUrl:
                    thumbnailImages.length > index
                      ? thumbnailImages[index].thumbnailUrl ??
                        thumbnailImages[index].url ??
                        imageUrl
                      : imageUrl,
                } as ImageType;
              });
              setFullSizeImages(newImages);
            });
            // Sometimes the getImages() call returns images that aren't
            // in project.resources (not sure why). We still need their
            // captureSource to know if they can be deleted, so let's fetch them.
            Promise.all(
              thumbnailImages
                .filter((image) => !image.source)
                .map((image) => {
                  return ResourceService.get(image.id).then(
                    (result: Resource) => ({
                      id: result.id,
                      url: result.url!,
                      thumbnailUrl: result.url!,
                      source: result.captureSource,
                      deleteable:
                        (result.captureSource === 'mobile' ||
                          result.captureSource === 'portal') &&
                        result.id !== project.primaryImageId,
                    })
                  );
                })
            ).then((thumbnailsWithCaptureSource) => {
              setImages(
                thumbnailImages.map((image) => {
                  if (image.source) {
                    return image;
                  }
                  const match = thumbnailsWithCaptureSource.find(
                    (thumnail) => thumnail.id === image.id
                  );
                  if (match) {
                    return match;
                  }
                  return image;
                })
              );
            });
          }
        );
      }
    } catch (error) {
      // If any call fails, hide the spinner & show/log an error
      setLoading(false);
      showError({
        title: 'Error Fetching Project Images',
        msg: 'Please try again later.',
      });
      logger.error('Failed getting project images: %o', error);
      throw error;
    }
  }, [inEditMode]);

  return (
    <ImageUploading
      value={inEditMode ? images : images.slice(0, 6)}
      onChange={inEditMode || !uploading ? onChange : () => false}
      dataURLKey={DATA_KEY}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemove,
        //onImageUpdate,
        //onImageRemoveAll,
        isDragging,
        dragProps,
        errors,
      }) => {
        return (
          <div className={className}>
            {errors && <Message compact list={getErrorMsgs(errors)} />}

            <div className="flex">
              {(inEditMode || loading || uploading) &&
                checkFeatureAuthorization(Features.GetImages, user?.role) && (
                  <UploadImageButton
                    dragging={isDragging}
                    onClick={onImageUpload}
                    loading={loading}
                    uploading={uploading}
                    disabled={!inEditMode || uploading}
                    dragProps={dragProps}
                  />
                )}

              {!loading && (
                <div className="carousel">
                  {imageList.map((image, index) => (
                    <ProjectImageList
                      key={index}
                      image={image}
                      index={index}
                      onOpen={() => onImageClick(index)}
                      canDelete={inEditMode && images[index].deleteable}
                      onClose={() => removeImage(image, index, onImageRemove)}
                    />
                  ))}
                </div>
              )}
            </div>

            <ImageGallery
              lazyLoad={true}
              ref={gallery}
              items={fullSizeImages.map((image) => ({
                original: image.url,
                thumbnail: image.thumbnailUrl,
              }))}
              useBrowserFullscreen={false}
              onScreenChange={setIsGalleryVisible}
              thumbnailPosition="top"
              additionalClass={'abs hidden ' + (galleryVisible ? 'show' : '')}
              showPlayButton={false}
              showFullscreenButton={true}
            />
          </div>
        );
      }}
    </ImageUploading>
  );
}
