import useSmartTableSortOrder, { TableType } from 'context/SmartTableContext';
import { genericModel, Model } from 'models/Model';
import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import {
  initialGlobalTableState,
  SmartTableSortOrder,
} from '../../context/SmartTableReducer';
import './SmartTable.scss';
import { camelCaseToWords, capitalize, toLowerCaseIfString } from '../../utils';

type SmartTableProps<T> = {
  tableType: TableType;
  data: T[];
  // @ts-ignore
  model?: Model<T>;
  filter?: (el: T) => boolean;
  onRowClick?: (el: T) => void;
  excludeProps?: string;
  transformProps?: { [prop: string]: (propValue: any, el?: any) => any };
  initialSortProp?: string;
  empty?: string | Element | JSX.Element;
  className?: string;
  exportData?: boolean;
  setExportData?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SmartTable<T>({
  tableType,
  data,
  // @ts-ignore
  model = genericModel(data),
  filter = () => true,
  onRowClick,
  excludeProps = '',
  transformProps = {},
  initialSortProp = model.fields[0].prop,
  empty = 'Nothing to display',
  className,
  exportData,
  setExportData,
}: SmartTableProps<T>): React.ReactElement {
  const {
    projectsTableState,
    projectsWithDealerTableState,
    employeesTableState,
    ordersTableState,
    dealersTableState,
    updateTableSortingState,
  } = useSmartTableSortOrder();
  const contextState =
    tableType === 'projects'
      ? projectsTableState
      : tableType === 'orders'
      ? ordersTableState
      : tableType === 'projectsWithDealer'
      ? projectsWithDealerTableState
      : tableType === 'dealers'
      ? dealersTableState
      : employeesTableState;

  const [sorting, setSorting] = useState(
    contextState.sorting || {
      ...initialGlobalTableState[tableType].sorting,
      prop: initialSortProp,
    }
  );

  useEffect(() => {
    updateTableSortingState(sorting, tableType);
  }, [JSON.stringify(sorting)]);

  const fields = excludeProps
    ? model.fields.filter(({ prop }) => !excludeProps.includes(` ${prop} `))
    : model.fields;

  const sortBy = useCallback(
    (prop: string) => {
      const order =
        contextState.sorting.prop === prop
          ? contextState.sorting.order === SmartTableSortOrder.ASC
            ? SmartTableSortOrder.DES
            : SmartTableSortOrder.ASC
          : SmartTableSortOrder.ASC;
      const newState = { prop, order };
      setSorting(newState);
    },
    [JSON.stringify(contextState.sorting)]
  );

  const sort = useCallback(
    (el1, el2): number => {
      const el1Value = model(el1).get(contextState.sorting.prop);
      const el2Value = model(el2).get(contextState.sorting.prop);
      const firstIsBigger =
        toLowerCaseIfString(el1Value) > toLowerCaseIfString(el2Value);
      return contextState.sorting.order === SmartTableSortOrder.ASC
        ? firstIsBigger
          ? 1
          : -1
        : firstIsBigger
        ? -1
        : 1;
    },
    [JSON.stringify(contextState.sorting), model]
  );

  const getDataToExport = () => {
    const filtered = data.filter(filter);
    const dataToExport = [];
    dataToExport.push(fields.map((field) => field?.label));
    for (let i = 0; i < filtered.length; i++) {
      const el = filtered[i];
      const row: string[] = [];
      fields.forEach((field) => {
        const value = model(el).get(field.prop);
        const transform = transformProps[field.prop];
        const displayVal =
          typeof transform === 'function' ? transform(value, el) : value;
        row.push(`"${displayVal}"`);
      });
      dataToExport.push(row);
    }
    return dataToExport;
  };

  const downloadBlob = (
    content: string,
    filename: string,
    contentType: string
  ) => {
    // Create a blob
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  };

  useEffect(() => {
    if (exportData) {
      const dataToExport = getDataToExport();
      const csvContent = dataToExport.map((e) => e.join(',')).join('\n');
      const fileName = (
        capitalize(tableType).match(/([A-Z]?[^A-Z]*)/g) ?? ['data']
      )
        .slice(0, -1)
        .join('-');

      // Export content to csv file
      downloadBlob(csvContent, fileName, 'text/csv;charset=utf-8;');

      // Reset export state so user can download file again
      if (setExportData) setExportData(false);
    }
  }, [exportData]);

  const renderData = useCallback(() => {
    const filtered = data.filter(filter);
    return filtered.length
      ? filtered.sort(sort).map((el: any, i) => (
          <Table.Row
            key={i}
            data-cy="cy-table-row"
            onClick={onRowClick ? () => onRowClick(el) : undefined}
            className={!onRowClick ? 'smart-table__row--unclickable' : ''}
          >
            {fields.map(({ prop }, i) => {
              const value = model(el).get(prop);
              const transform = transformProps[prop];
              return (
                <Table.Cell key={i}>
                  {typeof transform === 'function'
                    ? transform(value, el)
                    : value}
                </Table.Cell>
              );
            })}
          </Table.Row>
        ))
      : empty;
  }, [data, fields, model, empty, filter, sort, onRowClick, transformProps]);

  return (
    <div className={className}>
      <Table fixed striped selectable={!!onRowClick} sortable>
        <Table.Header className="sticky-header" fullWidth>
          <Table.Row>
            {fields.map(({ prop, label }, i) => {
              const isSorted = contextState.sorting.prop === prop;
              return (
                <Table.HeaderCell
                  key={i}
                  sorted={isSorted ? contextState.sorting.order : undefined}
                  onClick={() => sortBy(prop)}
                >
                  {camelCaseToWords(label!)}
                  {!isSorted && (
                    <i className="smart-table__sort-icon sort icon" />
                  )}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body data-cy="cy-table-body">{renderData()}</Table.Body>
      </Table>
    </div>
  );
}
