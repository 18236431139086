import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Button,
  Confirm,
  Form,
  Header,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { Modal as EditModal } from 'components';
import Loader from 'components/layout/loader/Loader';
import { FieldGroup, TransitionGroup } from 'components';
import { Dealer, DealerModel, PurchasePartner } from 'models';
import { useAuth, useUI } from 'services';
import { USER_MSG } from 'strings';
import {
  DealerPartner,
  PurchasePartnerWithBrands,
  TYPE_BUYING_GROUP,
  TYPE_DIRECT,
  TYPE_DISTRIBUTOR,
} from 'models/Dealer';
import { dealerDirectString } from 'components/orders/Forms/Partials/Product/ProductFormConstants';
import { CompanyService, PurchasePartners } from 'sections/company';
import { useRouteMatch } from 'react-router-dom';
import { DealerMsg } from './Constants';
import { EmployeeList } from 'sections/employee';
import { APIEntity } from 'services/APIService';
import { Role } from 'models/Role';

interface RouteParams {
  id: string;
}

export default function AdminCompanyDetail(): React.ReactElement {
  const [loading, setLoading] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const [dealer, setDealer] = useState(DealerModel({} as Dealer));
  const [brandsError, setBrandsError] = useState(false);
  const [purchasePartners, setPurchasePartners] =
    useState<PurchasePartnerWithBrands[]>();
  const { user } = useAuth();
  const { showError, showSuccess } = useUI();
  const {
    params: { id },
  } = useRouteMatch<RouteParams>();
  const hasEditAccess =
    user?.role === Role.BAI_ADMIN ||
    user?.role === Role.LATHAM_ADMIN ||
    user?.role === Role.CUST_SERV;

  const setDefaultPurchasePartner = (purchasePartner: PurchasePartner) => {
    CompanyService.set(
      //@ts-ignore
      { id: `mine/defaultPartner/${purchasePartner.code}` },
      'PATCH',
      false
    )
      .then(() => {
        showSuccess();
        fetchDealersAndBrands();
      })
      .catch((e) => {
        showError({ title: e.message, msg: e.message });
      });
  };

  const fetchDealerInfo = useCallback(async () => {
    try {
      const dealerInfo = await CompanyService.get(id);
      setDealer(DealerModel(dealerInfo));
      if (!dealerInfo.isUserDealer) {
        fetchBrandsInfo(dealerInfo);
      }
    } catch (error: any) {
      showError({ title: error.message, msg: error.message });
    }
  }, [user]);

  const fetchBrandsInfo = async (
    dealerInfo: APIEntity<Dealer> | DealerPartner
  ) => {
    setBrandsError(false);
    try {
      const purchasePartners = await CompanyService.getBrandsById(
        dealerInfo.rewardsId
      );

      const { direct, throughBuyingGroup, throughDistribution } =
        purchasePartners;
      const formattedPartners = direct
        ? [{ ...direct, name: dealerDirectString, type: TYPE_DIRECT }]
        : [];
      if (throughBuyingGroup?.name) {
        formattedPartners.push({
          ...throughBuyingGroup,
          type: TYPE_BUYING_GROUP,
        });
      }
      if (throughDistribution?.distributorCustomerAccount?.length) {
        throughDistribution.distributorCustomerAccount.forEach(
          (distributor) => {
            formattedPartners.push({ ...distributor, type: TYPE_DISTRIBUTOR });
          }
        );
      }

      setPurchasePartners(
        // Distributors without brands should not be shown
        formattedPartners.filter((partner) => partner.brands.length > 0)
      );
    } catch (error: any) {
      setBrandsError(true);
    }
  };

  const fetchDealersAndBrands = async () => {
    await fetchDealerInfo().then(() => {
      setLoading(false);
    });
  };

  const refreshDealer = async () => {
    try {
      setLoading(true);
      await CompanyService.addOrRefreshDealer(dealer.data.rewardsId).then(
        (dealerInfo) => {
          setLoading(false);
          const newDealer = {
            address: {
              address1: dealerInfo.partnersInfo.shipping_address_street,
              address2: '',
              city: dealerInfo.partnersInfo.shipping_address_city,
              state: dealerInfo.partnersInfo.shipping_address_state,
              postalcode: dealerInfo.partnersInfo.shipping_address_postalcode,
              country: dealerInfo.partnersInfo.shipping_address_country,
            },
            id: dealerInfo.dealerId,
            name: dealerInfo.partnersInfo.customer_name,
            phone: dealerInfo.partnersInfo.phone_office,
            rewardsId: dealerInfo.rewardsId,
          };
          showSuccess({
            title: DealerMsg.SUCCESS_TITLE,
            msg: DealerMsg.SUCCESS_MSG_UPDATE,
          });
          setDealer(DealerModel(newDealer));
          fetchBrandsInfo(dealerInfo);
        }
      );
    } catch (error: any) {
      setLoading(false);
      showError({ title: DealerMsg.ERROR_MSG_UPDATE, msg: error.message });
    }
  };

  const handleOpenEditModal = (): void => {
    setEditing(true);
  };

  useEffect(() => {
    if (!user?.dealerId) return;
    fetchDealersAndBrands();
  }, [user, fetchDealerInfo]);

  const updateDealer = useCallback(async () => {
    if (!dealer.isValid()) {
      showError(USER_MSG.ERROR_INVALID);
      return;
    }
    await CompanyService.updateDealer(dealer.data)
      .then(() => {
        setEditing(false);
        showSuccess();
      })
      .catch(() => {
        showError();
      });
  }, [dealer, showError, showSuccess]);

  const [form, setForm] = useState({
    loading: false,
    confirm: null as {
      title: string;
      msg: string;
      action: () => void;
      ok?: string;
    } | null,
  });

  const renderModelControls: React.ReactElement = useMemo(() => {
    return (
      <div className="controls">
        <Button basic onClick={() => setEditing(false)} content="Cancel" />
        {hasEditAccess && (
          <Button
            className="control-right"
            primary
            onClick={updateDealer}
            content={'Update'}
          />
        )}
      </div>
    );
  }, [dealer, setEditing, updateDealer]);

  return !dealer.data.id ? (
    <Loader text={USER_MSG.DEALER_NOT_FOUND} />
  ) : (
    <TransitionGroup transition="scale" isVisible={!!dealer.data.id}>
      <Modal open={loading}>
        <div className="ui large text loader">
          {DealerMsg.REFRESH_IN_PROGRESS}
        </div>
      </Modal>
      <EditModal
        isOpen={editing}
        actions={renderModelControls}
        size="small"
        title="Edit Profile"
      >
        <Form
          className="row"
          style={{ gap: '22px', justifyContent: 'space-evenly' }}
        >
          <Confirm
            className="employee-delete-modal"
            size="mini"
            open={!!form.confirm}
            header={form.confirm?.title}
            content={form.confirm?.msg}
            onConfirm={form.confirm?.action}
            confirmButton={form.confirm?.ok || 'OK'}
            onCancel={() => setForm({ loading: false, confirm: null })}
          />
          <FieldGroup
            fields="name email phone rewardsId"
            className="col"
            disabled={!hasEditAccess}
            model={dealer}
          />
          <div>
            <FieldGroup
              fields="address"
              disabled={!hasEditAccess}
              model={dealer}
            />
          </div>
        </Form>
      </EditModal>
      <Segment className="pad">
        {hasEditAccess && !dealer.data.isUserDealer && (
          <button
            className="ui primary button main-action"
            onClick={refreshDealer}
          >
            {DealerMsg.REFRESH}
          </button>
        )}
        {hasEditAccess && dealer.data.isUserDealer && (
          <button
            className="ui primary button main-action"
            onClick={handleOpenEditModal}
          >
            Edit Profile
          </button>
        )}
        <Form>
          <Header size="large">{DealerMsg.DEALER_INFO_TITLE}</Header>
          <div className="row">
            <FieldGroup
              model={dealer}
              fields="name address.address1 address.address2 address.city address.country rewardsId"
              disabled={true}
              className="col"
            />
            <FieldGroup
              model={dealer}
              fields="url email address.state address.postalcode phone"
              disabled={true}
              className="col"
            />
          </div>
        </Form>
      </Segment>

      {!dealer.data.isUserDealer && (
        <PurchasePartners
          error={brandsError}
          purchasePartners={purchasePartners}
          setDefaultPurchasePartner={setDefaultPurchasePartner}
          removePurchasePartner={() => null}
        />
      )}

      <EmployeeList dealerId={dealer.data.id} showTOSStatus />
    </TransitionGroup>
  );
}
