import React, { useEffect } from 'react';
import {
  ProductFormPropTypes,
  ProductFormSchemaForOrder,
  ProductFormSchemaForQuote,
  ProductSofetyFormSchemaForQuote,
} from '../components/orders/Forms/Partials/Product/ProductFormSchema';
import {
  FeaturesFormPropTypes,
  FeaturesFormSchema,
  VinlyCoverLinerFeaturesFormSchema,
} from '../components/orders/Forms/Partials/Features/FeaturesForm/FeaturesFormSchema';
import {
  AnchorsFormSchema,
  AnchorsFormType,
} from '../components/orders/Forms/Partials/Anchors/AnchorsForm/AnchorsFormSchema';
import {
  AccessoriesFormPropTypes,
  AccessoriesFormSchema,
} from '../components/orders/Forms/Partials/Accessories/AccessoriesFormSchema';
import { OrderStep } from '../components/orders/ModalNavigation/ModalNavigation';
import OrderReducer, { OrderActionType } from './OrderReducer';
import { OrderStatus, OrderType, ServerOrder } from '../models/Order';
import { LabeledResources } from '../sections/orders/Orders';
import { ValidationError } from 'yup';

import { API_URL } from '../services';
import { handleJsonResponse, logger } from '../utils';
import { BaseDeckFormType } from '../components/orders/Forms/Partials/Features/Feature/FeatureSchema';
import {
  ShippingFormPropTypes,
  ShippingFormSchema,
} from 'components/orders/Forms/Partials/Shipping/ShippingFormSchema';
import { ModelInstance } from 'models/Model';
import { Project } from 'models/Project';
import {
  ProjectFormPropTypes,
  ProjectFormSchemaForOrder,
  ProjectFormSchemaForOrderNoPo,
  ProjectFormSchemaForQuote,
} from 'components/orders/Forms/Partials/Project/ProjectFormSchema';
import {
  ANCHOR_NA,
  checkFeatureOrderType,
  isFeatureRemovable,
  isLinerFeature,
} from 'components/orders/Forms/Partials/Features/Feature/FeatureConstants';
import { chooseLaterString } from 'components/orders/Forms/Partials/Product/ProductFormConstants';
import { SpecFormPropTypes } from 'components/orders/Forms/Partials/Spec/SpecFormSchema';
import { FeatureType } from 'models';
import { isOptionsHidden } from 'components/orders/helpers/FeatureHelper';

interface isFormSectionValid {
  isValid: boolean;
}

export enum SwatchBrand {
  Latham_CA = 'Latham_CA',
  Latham_US = 'Latham_US',
  Premier = 'Premier',
}

export enum SwatchFabric {
  Solid = 'Solid',
  Mesh = 'Mesh',
}

export enum SwatchPattern {
  _500P_Solid = '500P',
  _1000HS_Solid = '1000HS',
  _5000M_Mesh = '5000M',
  _7000MS_Mesh = '7000MS',
  _9000MX_Mesh = '9000MX',
}

export enum SwatchColor {
  Green = 'Green',
  Blue = 'Blue',
  Grey = 'Grey',
  Tan = 'Tan',
  Black = 'Black',
}

export interface ClientSwatch {
  brand: SwatchBrand;
  brandId: string; // UUID
  color: SwatchColor;
  colorChar: string;
  fabric: SwatchFabric;
  fabricCode: string;
  id: string;
  meshOrSolid: string;
  pattern: SwatchPattern;
  createdAt: string;
  updatedAt: string;
  url: string;
}

export interface LinerMatchMaterial {
  '@_MilGauge': string;
  '@_Description': string;
  '@_WallCode': string;
  '@_BottomCode': string;
  '@_WallBeadCode': string;
  '@_WallBeadDesc': string;
  '@_MaterialMatchSpec': string;
}

export interface LinerWallMaterial {
  '@_MilGauge': string;
  '@_Description': string;
  '@_WallCode': string;
  '@_BeadCode': string;
  '@_BeadDesc': string;
}

export interface LinerBottomMaterial {
  '@_MilGauge': string;
  '@_Description': string;
  '@_BottomCode': string;
  '@_BeadCode': string;
  '@_BeadDesc': string;
}

export interface LinerStepMaterial {
  '@_MilGauge': string;
  '@_Description': string;
  '@_StepCode': string;
  '@_IsTextured': string;
}

export interface LinerMaterialOptions {
  Material?: LinerMatchMaterial[];
  WallMaterial?: LinerWallMaterial[];
  BottomMaterial?: LinerBottomMaterial[];
  StepMaterial?: LinerStepMaterial[];
}

export interface LinerMaterialTypeOptions {
  MatchMaterials: LinerMaterialOptions;
  WallMaterials: LinerMaterialOptions;
  BottomMaterials: LinerMaterialOptions;
  StepMaterials: LinerMaterialOptions;
  '@_Brand': string;
  '@_Disclaimer': string;
}

export type LinerMaterials = Record<
  string,
  Record<string, LinerMaterialTypeOptions>
>;

export interface LinerBeads {
  getBeadsOut: GetBeadsOut;
}

export interface GetBeadsOut {
  '@_status': string;
  BeadsList: BeadsList;
}

export interface BeadsList {
  '@_company': string;
  Beads: Beads;
  TruTileBeads: TruTileBeads;
}

export interface Beads {
  bead: bead[];
}

export interface bead {
  '@_BeadCode': string;
  '@_BeadDescription': string;
}

export interface TruTileBeads {
  truTileBead: truTileBead[];
}

export interface truTileBead {
  '@_TruTileBeadCode': string;
  '@_TruTileBeadDescription': string;
}

export type productOrderState = ProductFormPropTypes & isFormSectionValid;
export type projectOrderState = ProjectFormPropTypes & isFormSectionValid;
export type featureOrderState = FeaturesFormPropTypes & isFormSectionValid;
export type anchorsOrderState = AnchorsFormType & isFormSectionValid;
export type accessoriesOrderState = AccessoriesFormPropTypes &
  isFormSectionValid;
export type shippingOrderState = ShippingFormPropTypes & isFormSectionValid;
export type specOrderState = SpecFormPropTypes & isFormSectionValid;

export interface OrderState {
  step: number;
  steps: OrderStep[];
  order?: ServerOrder;
  spec: specOrderState;
  project: projectOrderState;
  product: productOrderState;
  features: featureOrderState;
  anchors: anchorsOrderState;
  accessories: accessoriesOrderState;
  shipping: shippingOrderState;
  featuresData: LabeledResources[];
  decksData: LabeledResources[];
  swatches: ClientSwatch[];
  linerMaterials?: Record<string, Record<string, LinerMaterialTypeOptions>>;
  linerBeads?: LinerBeads;
}

export const initialOrderState: OrderState = {
  step: 0,
  steps: [],
  order: undefined,
  accessories: {
    doubleD_Rings: false,
    yStraps: false,
    yStrapQty: undefined,
    strapLength: undefined,
    topAndBottomWebbing: false,
    buckles: false,
    kemkap: false,
    isValid: false,
  } as accessoriesOrderState,

  //@ts-ignore @todo it doesn't like decks
  anchors: {
    isValid: false,
    hardwareNeeded: '',
    decks: [],
  } as anchorsOrderState,

  //@ts-ignore
  features: {
    isValid: false,
    features: [],
  } as featureOrderState,

  project: {
    isValid: false,
    address1: '',
    address2: '',
    city: '',
    shippingState: '',
    postalCode: '',
    primaryContactName: '',
    poNumber: undefined,
    type: undefined,
  } as projectOrderState,

  product: {
    isValid: false,
    noDrainOrPumpAck: false,
    brand: undefined,
  } as productOrderState,

  shipping: {
    isValid: false,
  } as shippingOrderState,

  spec: {
    isValid: false,
  } as specOrderState,

  featuresData: [],
  decksData: [],
  swatches: [],
  linerMaterials: {} as LinerMaterials,
  linerBeads: {} as LinerBeads,
};

const orderDispatch: React.Dispatch<OrderActionType> = () => initialOrderState;

export const OrderContext = React.createContext({
  state: initialOrderState,
  dispatch: orderDispatch,
});

export const GetProductValues = (
  state: OrderState,
  dispatch: (value: OrderActionType) => void
) => {
  let gridSpacing = state.product.gridSpacing;
  let material = state.product.material;
  if (state.order?.status === OrderStatus.ReadyForOrder) {
    if (
      state.product.gridSpacing === chooseLaterString &&
      state.project?.type !== OrderType.Liner
    ) {
      gridSpacing = '';
    }
    if (state.product.material === chooseLaterString) {
      material = '';
    }
  }

  dispatch({
    type: 'updateProduct',
    payload: {
      gridSpacing: gridSpacing,
      material: material,
    } as Partial<productOrderState>,
  });
};

export const GetFeatureValuesOrDefault = (
  state: OrderState
): BaseDeckFormType[] => {
  if (state.featuresData.length) {
    const features = state.featuresData
      .filter((feat) =>
        checkFeatureOrderType(feat.type, state.project?.type ?? '')
      )
      .map((el, index) => {
        const feature = state?.features?.features?.find(
          (feat) => feat.id === el.id
        );
        const isFeatureSpa = el.type === FeatureType.Spa;
        const isFeatureVinylStep = el.type === FeatureType.VinylFeature;
        const needsAnchorType =
          !isLinerFeature(el.type) &&
          !isFeatureRemovable(el.type) &&
          ((!isFeatureSpa && !isOptionsHidden(el.type)) ||
            (isFeatureSpa && !el.isCovered));
        return {
          anchorTypeOptions: needsAnchorType
            ? feature?.anchorTypeOptions ?? undefined
            : ANCHOR_NA,
          cableAssembly: feature?.cableAssembly ?? undefined,
          featureLabel: el.label.replace('Vinyl Feature', 'VF'),
          featureType: el.type,
          featureName: el.featureName,
          isRemovable: feature?.isRemovable ?? el?.isRemovable ?? undefined,
          isCovered: feature?.isCovered ?? el?.isCovered ?? undefined,
          stepMaterialOption: isFeatureVinylStep
            ? feature?.stepMaterialOption ?? el?.stepMaterialOption ?? undefined
            : 'null',
          stepMaterial: isFeatureVinylStep
            ? feature?.stepMaterial ?? el?.stepMaterial ?? undefined
            : 'null',
          stepStripe: isFeatureVinylStep
            ? feature?.stepStripe ?? el?.stepStripe ?? undefined
            : 'null',
          stripeWidth: isFeatureVinylStep
            ? feature?.stripeWidth ?? el?.stripeWidth ?? undefined
            : 'null',
          differentTreads: isFeatureVinylStep
            ? feature?.differentTreads ?? el?.differentTreads ?? undefined
            : 'null',
          riserMaterial: isFeatureVinylStep
            ? feature?.riserMaterial ?? el?.riserMaterial ?? undefined
            : 'null',
          stepFastener: isFeatureVinylStep
            ? feature?.stepFastener ?? el?.stepFastener ?? undefined
            : 'null',
          stepComment: isFeatureVinylStep
            ? feature?.stepComment ?? el?.stepComment ?? undefined
            : 'null',
          notes: feature?.notes ?? el?.notes,
          id: feature?.id ?? el.id,
          measurement: el?.measurement ?? null,
        };
      });
    return features as BaseDeckFormType[];
  }
  return [];
};

/**
 *
 * @param children
 * @param order
 * @constructor
 */
const OrderProvider = ({
  children,
  order,
  project,
}: {
  children: React.ReactElement;
  order?: ServerOrder;
  project?: ModelInstance<Project>;
}) => {
  const [state, dispatch] = React.useReducer<
    React.Reducer<OrderState, OrderActionType>
  >(OrderReducer, {
    ...initialOrderState,
    project: {
      poolArea: project?.data?.poolDetails?.measurements?.poolAreaSqFt,
      coverOverlap: project?.data?.poolDetails?.measurements?.coverOverlapIn,
      poolShapeType: project?.data?.poolDetails?.poolShapeType,
      poolShape: project?.data?.poolDetails?.poolShape,
      address1: project?.data?.address.address1 ?? '',
      address2: project?.data?.address.address2 ?? '',
      city: project?.data?.address.city ?? '',
      shippingState: project?.data?.address.state ?? '',
      postalCode: project?.data?.address.postalcode ?? '',
      primaryContactName: project?.data?.customer?.name ?? '',
      isValid: true,
      poNumber: undefined,
      type: undefined,
    },
    order: { ...order } as ServerOrder,
  });
  const value = { state, dispatch };

  // @todo move these
  const validateFeatures = () => {
    if (!state?.features?.features?.length && state.featuresData.length) {
      const newFeatures = GetFeatureValuesOrDefault(state);
      dispatch({
        type: 'updateFeatures',
        payload: { isValid: false, features: newFeatures } as featureOrderState,
      });
    }
    if (
      state?.order?.status === OrderStatus.ReadyForOrder &&
      state?.order?.type === OrderType.Liner
    ) {
      VinlyCoverLinerFeaturesFormSchema.validate(state.features)
        .then(() => {
          dispatch({
            type: 'updateFeatures',
            payload: {
              isValid: true,
              isViewed: true,
            } as Partial<featureOrderState>,
          });
        })
        .catch((error: ValidationError) => {
          dispatch({
            type: 'updateFeatures',
            payload: { isValid: false } as Partial<featureOrderState>,
          });
        });
    } else {
      FeaturesFormSchema.validate(state.features)
        .then(() => {
          dispatch({
            type: 'updateFeatures',
            payload: {
              isValid: true,
              isViewed: true,
            } as Partial<featureOrderState>,
          });
        })
        .catch((error: ValidationError) => {
          dispatch({
            type: 'updateFeatures',
            payload: { isValid: false } as Partial<featureOrderState>,
          });
        });
    }
  };

  const validateAnchors = () => {
    AnchorsFormSchema.validate(state.anchors, {
      context: AnchorsFormSchema.cast({
        hardwareNeeded: state.anchors.hardwareNeeded,
        decks: state.anchors.decks,
      }),
    })
      .then(() => {
        dispatch({
          type: 'updateAnchors',
          payload: { isValid: true } as anchorsOrderState,
        });
      })
      .catch((error: ValidationError) => {
        dispatch({
          type: 'updateAnchors',
          payload: { isValid: false } as Partial<anchorsOrderState>,
        });
      });
  };

  const validateAccessories = () => {
    AccessoriesFormSchema.validate(state.accessories)
      .then(() => {
        dispatch({
          type: 'updateAccessories',
          payload: { isValid: true } as Partial<accessoriesOrderState>,
        });
      })
      .catch((error: ValidationError) => {
        dispatch({
          type: 'updateAccessories',
          payload: { isValid: false } as Partial<accessoriesOrderState>,
        });
      });
  };

  const validateProduct = () => {
    GetProductValues(state, dispatch);
    // Certain fields are optional/required depending on whether we have a quote or not
    const schemaToUse =
      state.order?.status === OrderStatus.QuotedOptions ||
      state.order?.status === OrderStatus.ReadyForOrder
        ? state.project?.type == OrderType.Liner
          ? ProductFormSchemaForOrder
          : ProductSofetyFormSchemaForQuote
        : state.project?.type == OrderType.Liner
        ? ProductFormSchemaForQuote
        : ProductSofetyFormSchemaForQuote;

    schemaToUse
      .validate(state.product)
      .then(() => {
        dispatch({
          type: 'updateProduct',
          payload: { isValid: true } as Partial<productOrderState>,
        });
      })
      .catch((error: ValidationError) => {
        dispatch({
          type: 'updateProduct',
          payload: { isValid: false } as Partial<productOrderState>,
        });
      });
  };

  const validateShipping = () => {
    ShippingFormSchema.validate(state.shipping)
      .then(() => {
        dispatch({
          type: 'updateShipping',
          payload: { isValid: true } as Partial<shippingOrderState>,
        });
      })
      .catch((error: ValidationError) => {
        dispatch({
          type: 'updateShipping',
          payload: { isValid: false } as Partial<shippingOrderState>,
        });
      });
  };

  const validateProject = () => {
    GetProductValues(state, dispatch);
    // Certain fields are optional/required depending on whether we have a quote or not
    const schemaToUse =
      state.order?.status === OrderStatus.QuotedOptions ||
      state.order?.status === OrderStatus.ReadyForOrder
        ? state.product.partner === 'Dealer Direct'
          ? ProjectFormSchemaForOrder
          : ProjectFormSchemaForOrderNoPo
        : ProjectFormSchemaForQuote;

    schemaToUse
      .validate(state.project)
      .then(() => {
        dispatch({
          type: 'updateProject',
          payload: { isValid: true } as Partial<projectOrderState>,
        });
      })
      .catch((error: ValidationError) => {
        dispatch({
          type: 'updateProject',
          payload: { isValid: false } as Partial<projectOrderState>,
        });
      });
  };

  const validateSpec = () => {
    dispatch({
      type: 'updateSpec',
      payload: { isValid: true } as Partial<specOrderState>,
    });
  };

  //@todo, I don't love this, but it's explicit and easy to change
  useEffect(() => {
    if (state.product) {
      validateProduct();
    }
    // Be careful not to run the validation on the result of the validation
  }, [JSON.stringify({ ...state.product, isValid: undefined })]);

  useEffect(() => {
    if (state.project) {
      validateProject();
    }
    // Be careful not to run the validation on the result of the validation
  }, [
    JSON.stringify({ ...state.order, ...state.project, isValid: undefined }),
  ]);

  useEffect(() => {
    if (state.features) {
      validateFeatures();
    }
    // Be careful not to run the validation on the result of the validation
  }, [
    JSON.stringify({ ...state.features, isValid: undefined }),
    state.featuresData,
  ]);

  useEffect(() => {
    if (state.anchors) {
      validateAnchors();
    }
    // Be careful not to run the validation on the result of the validation
  }, [JSON.stringify({ ...state.anchors, isValid: undefined })]);

  useEffect(() => {
    if (state.accessories) {
      validateAccessories();
    }
  }, [JSON.stringify({ ...state.accessories, isValid: undefined })]);

  useEffect(() => {
    if (state.shipping) {
      validateShipping();
    }
  }, [JSON.stringify({ ...state.shipping, isValid: undefined })]);

  useEffect(() => {
    if (state.spec) {
      validateSpec();
    }
  }, [JSON.stringify({ ...state.spec, isValid: undefined })]);

  /**
   * Initialize product image swatches
   */
  useEffect(() => {
    if (state.swatches.length > 0) {
      return;
    }
    fetch(`${API_URL}swatch/unique`, {
      method: 'GET',
      headers: { 'cache-control': 'no-cache' },
    })
      .then(handleJsonResponse)
      .then((response: ClientSwatch[]) => {
        dispatch({
          type: 'setSwatches',
          payload: response,
        });
      })
      .catch((error) => {
        logger.error('Error getting image swatches: %o', error);
      });
  }, [state.swatches]);

  useEffect(() => {
    if (state.linerMaterials && Object.keys(state.linerMaterials).length > 0) {
      return;
    }
    fetch(`${API_URL}swatch/${project?.data.dealerId}/linerMaterials`, {
      method: 'GET',
      headers: { 'cache-control': 'no-cache' },
    })
      .then(handleJsonResponse)
      .then((response: LinerMaterials) => {
        dispatch({
          type: 'setLinerMaterials',
          payload: response,
        });
      })
      .catch((error) => {
        logger.error('Error getting linerMaterials: %o', error);
      });
  }, [state.linerMaterials]);

  useEffect(() => {
    if (state.linerBeads && state.linerBeads.getBeadsOut) {
      return;
    }
    fetch(`${API_URL}swatch/${project?.data.dealerId}/linerBeads`, {
      method: 'GET',
      headers: { 'cache-control': 'no-cache' },
    })
      .then(handleJsonResponse)
      .then((response: LinerBeads) => {
        dispatch({
          type: 'setLinerBeads',
          payload: response,
        });
      })
      .catch((error) => {
        logger.error('Error getting setLinerBeads: %o', error);
      });
  }, [state.linerBeads]);

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

export default OrderProvider;
