import { TransitionGroup } from 'components';
import React, { useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import { Dealer, Project, DealerProjects } from 'models';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import './Admin.scss';
import { Order } from 'models/Order';
import ProjectTab from './DataTabs/ProjectTab';
import OrderTab from './DataTabs/OrderTab';
import OneToManyTab from './DataTabs/OneToManyTab';
import OverviewTab from './DataTabs/OverviewTab';

interface AdminMeasureDataProps {
  projects: Project[];
  projectsWithDealer: DealerProjects[];
  dealers: Dealer[];
  orders: Order[];
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AdminMeasureData(
  props: AdminMeasureDataProps
): React.ReactElement {
  const { projects, projectsWithDealer, dealers, orders, loaded, setLoaded } =
    props;

  // Filter out test dealer projects
  const dealersToRemove = [
    'R06c1de8',
    'R0000000',
    'R1234567',
    'R06c1de8',
    'R837bcef',
  ];
  const filteredProjects = projects.filter((proj) => {
    const rewardsId =
      dealers.find((dealer) => dealer.id === proj?.dealerId)?.rewardsId ?? '';
    return !dealersToRemove.includes(rewardsId);
  });

  const filteredDealerProjects = projectsWithDealer.filter((dealerProject) => {
    const list = dealerProject.projectIds.filter((proj) => {
      const dealerId = projects.find((val) => val.id === proj)?.dealerId ?? '';
      const rewardsId =
        dealers.find((dealer) => dealer.id === dealerId)?.rewardsId ?? '';
      return !dealersToRemove.includes(rewardsId);
    });
    let scanCount = 0;
    let singleScanCount = 0;
    let multiScanCount = 0;
    let billableProjectCount = 0;
    let duplicateProjectCount = 0;
    list.filter((proj) => {
      const project = projects.find((val) => val.id === proj);
      scanCount += project?.scans?.length ?? 0;
      if (project?.scans?.length === 1) {
        singleScanCount++;
      } else if (project?.scans?.length ?? 0 > 1) {
        multiScanCount++;
      }
      if (
        project?.address['address1'] === '' &&
        project.address['city'] === '' &&
        project.address['state'] === '' &&
        project.address['postalcode'] === ''
      ) {
        billableProjectCount++;
      } else {
        duplicateProjectCount++;
      }
    });
    dealerProject.projectCount = list.length;
    dealerProject.scanCount = scanCount;
    dealerProject.singleScanCount = singleScanCount;
    dealerProject.multiScanCount = multiScanCount;
    dealerProject.billableProjectCount = billableProjectCount;
    dealerProject.duplicateProjectCount = duplicateProjectCount;
    return dealerProject;
  });

  const filteredOrders = orders.filter((order) => {
    return filteredProjects.find((proj) => proj.id === order.projectId);
  });

  const filterByCreatedDate = (createdDate: string, el: Project | Order) => {
    if (!createdDate) return true;
    const createdAt = new Date(el.createdAt);
    const [startDateStr, endDateStr] = createdDate.split('-');
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    endDate.setDate(endDate.getDate() + 1); // Adding one more day to include the selection in the range
    return startDate <= createdAt && createdAt <= endDate;
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="admin">
      <TransitionGroup isVisible={loaded}>
        <Tab
          menu={{ pointing: true }}
          className="tab-bar"
          panes={[
            {
              menuItem: 'Overview',
              render: () => (
                <Tab.Pane className="data-content" attached={false}>
                  {
                    <OverviewTab
                      projects={filteredProjects}
                      orders={orders}
                      loaded={loaded}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Projects',
              render: () => (
                <Tab.Pane className="data-content" attached={false}>
                  {
                    <ProjectTab
                      projects={filteredProjects}
                      dealers={dealers}
                      loaded={loaded}
                      filterByCreatedDate={filterByCreatedDate}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Dealer Project Stats',
              render: () => (
                <Tab.Pane className="data-content" attached={false}>
                  {
                    <OneToManyTab
                      projects={filteredProjects}
                      projectsWithDealer={filteredDealerProjects}
                      dealers={dealers}
                      loaded={loaded}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Quotes/Orders',
              render: () => (
                <Tab.Pane className="data-content" attached={false}>
                  {
                    <OrderTab
                      projects={filteredProjects}
                      dealers={dealers}
                      orders={filteredOrders}
                      loaded={loaded}
                      filterByCreatedDate={filterByCreatedDate}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
          ]}
        />
      </TransitionGroup>
    </div>
  );
}
