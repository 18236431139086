import React, { useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { ManagedSelect } from 'components/orders/Fields/Select/ManagedSelect';
import { ManagedToggle } from 'components/orders/Fields/Toggle/ManagedToggle';
import { BaseDeckFormType } from './FeatureSchema';
import './Feature.scss';
import { FeatureFormFieldValidationStatus } from '../FeaturesForm/FeaturesForm';
import {
  anchorTypeOptions,
  ANCHOR_NA,
  isFeatureRemovable,
  NO,
  YES,
  removableTypeOptions,
  featureInOverlap,
} from './FeatureConstants';
import { ImageType } from 'react-images-uploading';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { projectOrderState } from 'context/OrderContext';
import { FeatureMeasurements } from 'models/Project';
import { FeatureType } from 'models';
import { isOptionsHidden } from 'components/orders/helpers/FeatureHelper';

interface CoverFeatureProps {
  name: string;
  title: string;
  type: FeatureType;
  notes?: string;
  fieldNotes?: string;
  featureNotes?: string;
  setFeature: (feature: BaseDeckFormType) => void;
  validFields: FeatureFormFieldValidationStatus;
  feature: featureValuesType;
  images: ImageType[];
  archived?: boolean;
  measurement?: FeatureMeasurements;
  project: projectOrderState;
}

export interface featureValuesType {
  id: string;
  anchorTypeOptions?: string;
  cableAssembly?: boolean;
  isRemovable?: boolean;
  isCovered?: boolean;
  notes?: string;
}

export const CoverFeature = ({
  name,
  title,
  type,
  notes,
  fieldNotes,
  featureNotes,
  feature,
  setFeature,
  validFields,
  images,
  archived,
  measurement,
  project,
}: CoverFeatureProps) => {
  const checkRemovable = isFeatureRemovable(type);
  const isOther = type === FeatureType.Other;
  const isSpa = type === FeatureType.Spa;
  const isRock = type === FeatureType.DivingObject;
  const isCutout = type === FeatureType.DivingBoard;
  const isWall = type === FeatureType.Wall;
  const isInOverlap = featureInOverlap(isCutout, project, measurement);

  const hideAnchorCableOptions = isOptionsHidden(type);
  const naAnchor = !checkRemovable && !hideAnchorCableOptions;

  // N/A is not a valid option for Wall features
  const anchorOptions = isWall
    ? anchorTypeOptions.slice(1, anchorTypeOptions.length)
    : anchorTypeOptions;

  // Field states
  const [anchorType, setAnchorType] = useState<string | undefined>(
    naAnchor ? feature?.anchorTypeOptions ?? undefined : ANCHOR_NA
  );

  const [cableAssembly, setCableAssembly] = useState(
    feature?.cableAssembly ?? false
  );

  const [isRemovable, setIsRemovable] = useState(
    feature?.isRemovable ? YES : NO
  );

  const [isCovered, setIsCovered] = useState(feature?.isCovered ? YES : NO);

  const [showAnchorType, setShowAnchorType] = useState(!feature?.isCovered);

  const [featNotes, setFeatNotes] = useState(feature?.notes ?? notes);

  const [appNote, setAppNotes] = useState<string | undefined>(fieldNotes);
  const [portalNotes, setPortalNotes] = useState<string | undefined>(
    featureNotes
  );

  const [galleryVisible, setIsGalleryVisible] = useState(false);
  const gallery = useRef<ImageGallery | null>(null);

  const [visibility, setVisibility] = useState('hidden');
  const [style, setStyle] = useState<any>({
    boxShadow: '-1px 1px 8px rgb(0 0 0 / 41%)',
  });

  const onImageClick = (
    ref: React.MutableRefObject<ImageGallery | null>,
    index: number
  ) => {
    ref.current?.slideToIndex(index);
    ref.current?.fullScreen();
  };

  useEffect(() => {
    if (!name && !cableAssembly && !anchorType) {
      return;
    }

    setFeature({
      ...feature,
      anchorTypeOptions: anchorType,
      cableAssembly: cableAssembly,
      isRemovable: isRemovable === YES,
      isCovered: isCovered === YES,
      featureName: name,
      notes: featNotes,
      id: feature.id,
    } as BaseDeckFormType);
  }, [anchorType, cableAssembly, isRemovable, isCovered, featNotes]);

  useEffect(() => {
    if (portalNotes && portalNotes != 'null') {
      setFeatNotes(appNote + '. ' + portalNotes);
    } else {
      setFeatNotes(appNote);
    }
  }, [portalNotes]);

  useEffect(() => {
    setShowAnchorType(isCovered === NO);
    if (isSpa) {
      setAnchorType(isCovered !== NO ? ANCHOR_NA : anchorType);
    }
  }, [isCovered]);

  useEffect(() => {
    if (isRemovable === YES && !isOther) {
      setAnchorType(ANCHOR_NA);
    }
  }, [isRemovable]);

  const setHoverStyle = () => {
    setVisibility('visible');
    setStyle({
      cursor: 'pointer',
      background: '#fff',
      border: 'none',
      webkitBoxShadow: '0 1px 3px 0 #bcbdbd,0 0 0 1px #d4d4d5',
      boxShadow: '0 1px 3px 0 #bcbdbd,0 0 0 1px #d4d4d5',
      webkitTransform: 'translateY(-2px)',
      transform: 'translateY(-2px)',
    });
  };

  const clearHoverStyle = () => {
    setVisibility('hidden');
    setStyle({
      boxShadow: '-1px 1px 8px rgb(0 0 0 / 41%)',
    });
  };

  return (
    <div className="feature">
      <div className="feature__title">{title}</div>
      {isInOverlap && (
        <span className="featureContainer">
          <i className="featureIcon exclamation triangle icon"></i>
          <div className="featureWarning">
            The diving board is within the overlap and will require a cutout.
          </div>
        </span>
      )}
      {images.length > 0 ? (
        <>
          <div
            className="image-container-large"
            onClick={() => onImageClick(gallery, 0)}
            onMouseEnter={() => setHoverStyle()}
            onMouseLeave={() => clearHoverStyle()}
          >
            <img
              src={images[0]?.url}
              className="feature__image"
              style={style}
              onClick={() => onImageClick(gallery, 0)}
            />
            <div
              className="icon-container-large"
              style={{
                visibility: visibility,
              }}
            >
              <i className="zoom-in icon"></i>
            </div>
          </div>
          <ImageGallery
            lazyLoad={true}
            ref={gallery}
            items={images.map((img: any) => ({
              original: img?.url,
              thumbnail: img?.url,
            }))}
            useBrowserFullscreen={false}
            onScreenChange={setIsGalleryVisible}
            thumbnailPosition="top"
            additionalClass={'abs hidden ' + (galleryVisible ? 'show' : '')}
            showPlayButton={false}
            showFullscreenButton={true}
          />
        </>
      ) : (
        <div>No image found.</div>
      )}
      <ManagedInput
        label="Field Notes"
        isValid={validFields.notes}
        type="text"
        placeholder="Field Notes"
        onChange={setAppNotes}
        value={appNote}
        disabled={true}
      />
      <ManagedInput
        label="Feature Notes"
        isValid={true}
        type="text"
        placeholder="Feature Notes"
        onChange={setPortalNotes}
        value={portalNotes}
        disabled={archived}
      />
      {isSpa && (
        <>
          <ManagedSelect
            label="Is Spa Covered?"
            isValid={validFields.isCovered}
            value={isCovered}
            onChange={setIsCovered}
            options={removableTypeOptions}
            name="is_covered"
            disabled={archived}
          />
        </>
      )}
      {isRock && (
        <>
          <ManagedSelect
            label="Does object extend below deck?"
            isValid={validFields.isCovered}
            value={isCovered}
            onChange={setIsCovered}
            options={removableTypeOptions}
            name="is_extends_below"
            disabled={archived}
          />
        </>
      )}
      {(checkRemovable || isOther) && (
        <>
          <ManagedSelect
            label="Is Feature Removable?"
            isValid={validFields.isRemovable}
            value={isRemovable}
            onChange={setIsRemovable}
            options={removableTypeOptions}
            name="is_removable"
            disabled={archived}
          />
        </>
      )}
      {(isRemovable === NO || isOther) && !hideAnchorCableOptions && (
        <>
          {((isSpa && showAnchorType) || !isSpa) && (
            <ManagedSelect
              label="Anchor Type"
              isValid={validFields.anchorTypeOptions}
              value={anchorType}
              onChange={setAnchorType}
              options={anchorOptions}
              name="deck_type"
              disabled={archived || !naAnchor}
            />
          )}
          <ManagedToggle
            label="Cable Assembly"
            name="cableAssembly"
            isValid={validFields.cableAssembly}
            onChange={setCableAssembly}
            checked={cableAssembly}
            disabled={archived}
          />
        </>
      )}
    </div>
  );
};
