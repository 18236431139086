import React, { useState } from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import { UserProfile } from 'sections/user';
import { useAuth } from 'services';

export default function Header(): React.ReactElement {
  const [profile, setProfile] = useState(false);
  const { user, logout } = useAuth();

  return (
    <>
      <Menu style={{ height: '100%', borderRadius: 0 }}>
        <Menu.Item className="no-border" position="right">
          <Dropdown
            simple
            direction="left"
            text={user?.name}
            style={{ color: 'var(--color-teal)' }}
            data-cy="cy-user-menu"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="user"
                text="User Profile"
                data-cy="cy-user-menu-profile"
                onClick={() => setProfile(true)}
              />
              <Dropdown.Item icon="power" text="Log Out" onClick={logout} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu>
      {profile && (
        <UserProfile onCancel={() => setProfile(false)} isOpen={profile} />
      )}
    </>
  );
}
