import { FeatureType } from 'models';

export const getFeatureTypeOptions = () => {
  const options = [];
  for (const val in FeatureType) {
    if (val !== FeatureType.Calibration && val !== FeatureType.Perimeter) {
      options.push({
        label: val,
        value: val,
      });
    }
  }
  return options;
};

export const SolidShallowEndOptions = [
  { label: 'Black', value: 'Black' },
  { label: 'Blue', value: 'Blue' },
  { label: 'White', value: 'White' },
];
