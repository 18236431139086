import { createModel, DataFromSchema } from 'models/Model';

const required = true;

const createDealerSchema = {
  name: { type: 'text', label: 'Company', required },
  address: {
    address1: { type: 'text', label: 'Street', required },
    address2: { type: 'text', label: 'Street 2' },
    city: { type: 'text', label: 'City', required },
    state: { type: 'text', label: 'State', required },
    postalcode: { type: 'text', label: 'Zip', required },
    country: { type: 'text', label: 'Country', required },
  },
  url: { type: 'text', label: 'Web' },
  rewardsId: { type: 'text', label: 'Latham Rewards ID' },
  email: { type: 'email', label: 'Contact Email', required },
  phone: { type: 'tel', label: 'Contact Phone', required },
  isUserDealer: {
    type: 'toggle',
    label: 'One to Many Profile Setup',
  },
} as const;

export type NewDealer = DataFromSchema<typeof createDealerSchema> &
  DealerMetaData;

export const CreateDealerModel = createModel<NewDealer>(createDealerSchema);

const dealerSchema = {
  name: { type: 'text', label: 'Company', required },
  address: {
    address1: { type: 'text', label: 'Street', required },
    address2: { type: 'text', label: 'Street 2' },
    city: { type: 'text', label: 'City', required },
    state: { type: 'text', label: 'State', required },
    postalcode: { type: 'text', label: 'Zip', required },
    country: { type: 'text', label: 'Country', required },
  },
  url: { type: 'text', label: 'Web' },
  rewardsId: { type: 'text', label: 'Latham Rewards ID', required },
  email: { type: 'email', label: 'Contact Email', required },
  phone: { type: 'tel', label: 'Contact Phone', required },
  isUserDealer: {
    type: 'toggle',
    label: 'One to Many Profile Setup',
    required,
  },
} as const;
// ☝️ this const assertion is needed for DataFromSchema generic to do its magic

type DealerMetaData = {
  id: string;
  primaryUserId: string;
  createdAt: string;
  distributors?: Array<any>;
};

export type Dealer = DataFromSchema<typeof dealerSchema> & DealerMetaData;

export const DealerModel = createModel<Dealer>(dealerSchema);

const dealerPartnerSchema = {
  dealerId: { type: 'text', label: 'Dealer ID', required },
  partnersInfo: {
    customer_name: { type: 'text', label: 'Dealer ID', required },
    phone_office: { type: 'text', label: 'Dealer ID', required },
    shipping_address_city: { type: 'text', label: 'Dealer ID', required },
    shipping_address_country: { type: 'text', label: 'Dealer ID', required },
    shipping_address_postalcode: { type: 'text', label: 'Dealer ID', required },
    shipping_address_state: { type: 'text', label: 'Dealer ID', required },
    shipping_address_street: { type: 'text', label: 'Dealer ID', required },
    sugar_id: { type: 'text', label: 'Dealer ID', required },
  },
  partnersInfoMd5: { type: 'text', label: 'Partners Info MD5', required },
  rewardsId: { type: 'text', label: 'Latham Rewards ID', required },
} as const;

export type DealerPartner = DataFromSchema<typeof dealerPartnerSchema>;

export const DealerPartnerModel =
  createModel<DealerPartner>(dealerPartnerSchema);

export const TYPE_BUYING_GROUP = 'Buying Group';
export const TYPE_DISTRIBUTOR = 'Distributor';
export const TYPE_DIRECT = 'Buy Direct from Latham';

const purchasePartnerSchema = {
  name: { type: 'text', label: 'Distributor Name', required },
  code: { type: 'text', label: 'Code', required },
  branchCode: { type: 'text', label: 'Branch Code', required },
  isDefault: { type: 'text', label: 'Default' },
  type: {
    type: 'select',
    label: 'Type',
    values: [TYPE_BUYING_GROUP, TYPE_DISTRIBUTOR, TYPE_DIRECT],
    required,
  },
} as const;

type PurchasePartnerMetaData = {
  id: string;
  createdAt: string;
};

export type PurchasePartner = DataFromSchema<typeof purchasePartnerSchema> &
  PurchasePartnerMetaData;

export interface PurchasePartnerWithBrands extends PurchasePartner {
  brands: {
    id: string;
    name: string;
    product: string;
  }[];
}

export const PurchasePartnerModel = createModel<PurchasePartner>(
  purchasePartnerSchema
);
