import React, { SetStateAction, useEffect, useState } from 'react';
import './SpecForm.scss';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { ObstructionTypeArray } from './SpecFormSchema';
import { OrderContext } from 'context/OrderContext';
import { BaseDeckFormType } from '../Features/Feature/FeatureSchema';
import { getLagFeatureId } from '../Features/Feature/FeatureConstants';

let changedObstructionFeatureId = false;
interface ObstructionProps {
  setObstruction: (obstruction: ObstructionTypeArray) => void;
  obstruction: ObstructionTypeArray | null;
  archived?: boolean;
}

export interface obstructionValuesType {
  id: string;
  anchorTypeOptions?: string;
  cableAssembly?: boolean;
  isRemovable?: boolean;
  isCovered?: boolean;
  isOverWater?: boolean;
  notes?: string;
}
const getAnchorTypeOptions = (
  obstruction: ObstructionTypeArray | null,
  features?: BaseDeckFormType[]
) => {
  return features?.find((feature) => {
    return feature.featureName === obstruction?.obstructionLabel;
  })?.anchorTypeOptions;
};
const getObstructionFeatureId = (obstruction: ObstructionTypeArray | null) => {
  if (changedObstructionFeatureId) {
    return obstruction?.obstructionFeatureId;
  }
  const { state } = React.useContext(OrderContext);
  const changedFeatures = state?.features.features;
  const newAnchorTypeOptions = getAnchorTypeOptions(
    obstruction,
    changedFeatures
  );
  if (newAnchorTypeOptions) {
    return getLagFeatureId(newAnchorTypeOptions);
  }
  return obstruction?.obstructionFeatureId;
};
const Obstruction = ({
  obstruction,
  setObstruction,
  archived,
}: ObstructionProps) => {
  // Field states
  const [obstructionComments, setObstructionComments] = useState<
    string | undefined
  >(obstruction?.obstructionComments);
  const [obstructionFeatureId, setObstructionFeatureId] = useState<
    string | undefined
  >(getObstructionFeatureId(obstruction));
  const [obstructionHeight, setObstructionHeight] = useState<
    string | undefined
  >(obstruction?.obstructionHeight?.toString());
  const [obstructionLabel, setObstructionLabel] = useState<string | undefined>(
    obstruction?.obstructionLabel
  );
  const [obstructionLength, setObstructionLength] = useState<
    string | undefined
  >(obstruction?.obstructionLength?.toString());
  const [obstructionSpaCoveredFeatureSId, setObstructionSpaCoveredFeatureSId] =
    useState<string | undefined>(obstruction?.obstructionSpaCoveredFeatureSId);
  const [obstructionType, setObstructionType] = useState<string | undefined>(
    obstruction?.obstructionType
  );
  const [obstructionUpOverFeatureSId, setObstructionUpOverFeatureSId] =
    useState<string | undefined>(obstruction?.obstructionUpOverFeatureSId);

  const updateObstructionFeatureId = (
    obstructionFeatureId: SetStateAction<string | undefined>
  ) => {
    changedObstructionFeatureId = true;
    setObstructionFeatureId(obstructionFeatureId);
  };
  useEffect(() => {
    setObstruction({
      obstructionComments: obstructionComments ?? '',
      obstructionFeatureId: obstructionFeatureId ?? '',
      obstructionHeight: parseInt(obstructionHeight ?? '0'),
      obstructionLabel: obstructionLabel ?? '',
      obstructionLength: parseInt(obstructionLength ?? '0'),
      obstructionSpaCoveredFeatureSId: obstructionSpaCoveredFeatureSId ?? '',
      obstructionType: obstructionType ?? '',
      obstructionUpOverFeatureSId: obstructionUpOverFeatureSId ?? '',
    });
  }, [
    obstructionComments,
    obstructionFeatureId,
    obstructionHeight,
    obstructionLabel,
    obstructionLength,
    obstructionSpaCoveredFeatureSId,
    obstructionType,
    obstructionUpOverFeatureSId,
  ]);

  return (
    <>
      {obstructionLabel && (
        <div className="spec-form__second-title">
          {`Feature: ${obstructionLabel}`}
        </div>
      )}
      <ManagedInput
        isValid={true}
        label="Obstruction Comments"
        type="text"
        placeholder="Obstruction Comments"
        onChange={setObstructionComments}
        value={obstructionComments}
        disabled={archived}
      />

      <ManagedInput
        isValid={true}
        label="Obstruction FeatureId"
        type="text"
        placeholder="Obstruction FeatureId"
        onChange={updateObstructionFeatureId}
        value={obstructionFeatureId}
        disabled={archived}
      />

      <ManagedInput
        isValid={true}
        label="Obstruction Height"
        type="text"
        placeholder="Obstruction Height"
        onChange={setObstructionHeight}
        value={obstructionHeight}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Obstruction Label"
        type="text"
        placeholder="Obstruction Label"
        onChange={setObstructionLabel}
        value={obstructionLabel}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Obstruction Length"
        type="text"
        placeholder="Obstruction Length"
        onChange={setObstructionLength}
        value={obstructionLength}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Obstruction Spa FeatureSId"
        type="text"
        placeholder="Obstruction Spa FeatureSId"
        onChange={setObstructionSpaCoveredFeatureSId}
        value={obstructionSpaCoveredFeatureSId}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Obstruction Type"
        type="text"
        placeholder="Obstruction Type"
        onChange={setObstructionType}
        value={obstructionType}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Obstruction Up and Over FeatureSId"
        type="text"
        placeholder="Obstruction Up and Over FeatureSId"
        onChange={setObstructionUpOverFeatureSId}
        value={obstructionUpOverFeatureSId}
        disabled={archived}
      />
    </>
  );
};

export default Obstruction;
