export enum Role {
  USER = 'buyer',
  ADMIN = 'admin',
  CUST_SERV = 'cust_serv',
  DESIGN = 'design',
  SALES = 'sales',
  LATHAM_ADMIN = 'latham_admin',
  BAI_ADMIN = 'bright_admin',
  MEASURE_LITE = 'measure_lite',
}
